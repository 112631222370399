import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const Auth0UserChangeDetectedModal = (props) => {
    const { enforceFocus } = props;
    return (
        <Modal dataViewName="session-invalid-modal" enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        You have been logged out
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    It looks like you have logged in with a different user account in another tab. Refresh the page to continue with the new user.
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          action
                          label="Refresh"
                          onClick={() => {
                              window.location.reload();
                          }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

Auth0UserChangeDetectedModal.propTypes = {
    enforceFocus: PropTypes.bool
};

export default Auth0UserChangeDetectedModal;
