import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Auth0LoginRedirect = ({ loginWithRedirect }) => {
    useEffect(() => {
        loginWithRedirect({ appState: { returnTo: `${window.location.pathname}${window.location.search}` } });
    });
    return null;
};

Auth0LoginRedirect.propTypes = {
    loginWithRedirect: PropTypes.func.isRequired
};

export default Auth0LoginRedirect;
