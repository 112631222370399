import {
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    DELETE_USER_SUCCESS,
    CREATE_ONBOARDING_USER_SUCCESS,
    CREATE_ONBOARDING_USER_ERROR,
    TRANSFER_AND_DELETE_SUCCESS,
    DELETE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR
} from 'src/actions/users';
import {
    CREATE_SPACE_ERROR,
    CREATE_SPACE_SUCCESS,
    DELETE_SPACE_SUCCESS,
    DELETE_SPACE_ERROR,
    UPDATE_SPACE_SUCCESS,
    UPDATE_SPACE_ERROR,
} from 'src/actions/spaces';
import { put, takeEvery } from 'redux-saga/effects';
import {
    LIMITS_GET_REQUEST,
    limitsGetError,
    limitsGetSuccess
} from 'src/actions/limits';
import { parseClientLimit, parseSpaceLimit } from 'src/parsers';
import {
    PROFILE_ADD_ERROR,
    PROFILE_DELETE_ERROR,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS
} from 'src/actions/profiles';
import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import _has from 'lodash/has';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { reportError } from 'src/utils/reportError';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    DASHBOARD_REPORT_DEACTIVATE_SUCCESS,
    DASHBOARD_REPORT_UPDATE_ERROR,
    DASHBOARD_REPORT_UPDATE_SUCCESS,
    DASHBOARD_REPORT_ADD_SUCCESS
} from 'src/actions/dashboardReports';
import {
    GET_DATA_PUSH_TASKS_REQUEST,
    GET_DATA_PUSH_TASK_REQUEST,
    DATA_PUSH_TASK_UPDATE_SUCCESS,
    DATA_PUSH_TASK_DELETE_SUCCESS,
    DATA_PUSH_TASK_CREATE_SUCCESS
} from 'src/actions/dataPushTasks';

function* limitsGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-limits');
        if (response) {
            const parsedSpaceLimits = _has(response.jsonData, 'spaceLimits') ? parseSpaceLimit(response.jsonData.spaceLimits) : {};
            const parsedClientLimits = _has(response.jsonData, 'clientLimits') ? parseClientLimit(response.jsonData.clientLimits) : {};
            yield put(limitsGetSuccess(parsedSpaceLimits, parsedClientLimits));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(limitsGetError(applicationOrServerError));
    }
}

export default function* limitsSagas() {
    yield takeEvery([
        LIMITS_GET_REQUEST,
        PROFILE_ADD_ERROR,
        PROFILE_DELETE_ERROR,
        PROFILE_DELETE_SUCCESS,
        PROFILE_FORCE_DELETE_SUCCESS,
        DASHBOARD_REPORT_ADD_SUCCESS,
        DASHBOARD_REPORT_UPDATE_SUCCESS,
        DASHBOARD_REPORT_UPDATE_ERROR,
        CREATE_SPACE_ERROR,
        CREATE_SPACE_SUCCESS,
        CREATE_USER_ERROR,
        CREATE_USER_SUCCESS,
        DELETE_SPACE_ERROR,
        DELETE_SPACE_SUCCESS,
        DELETE_USER_ERROR,
        DELETE_USER_SUCCESS,
        TRANSFER_AND_DELETE_SUCCESS,
        UPDATE_SPACE_ERROR,
        UPDATE_SPACE_SUCCESS,
        UPDATE_USER_ERROR,
        UPDATE_USER_SUCCESS,
        ACCOUNT_ENTITIES_GET_SUCCESS,
        DASHBOARD_REPORT_DEACTIVATE_SUCCESS,
        CREATE_ONBOARDING_USER_SUCCESS,
        CREATE_ONBOARDING_USER_ERROR,
        GET_DATA_PUSH_TASKS_REQUEST,
        GET_DATA_PUSH_TASK_REQUEST,
        DATA_PUSH_TASK_UPDATE_SUCCESS,
        DATA_PUSH_TASK_DELETE_SUCCESS,
        DATA_PUSH_TASK_CREATE_SUCCESS
    ], limitsGetRequest);
}
