import {
    ADD_FIRST_APP_STARTED_TIME_REQUEST,
    addFirstAppStartedTimeError,
    addFirstAppStartedTimeSuccess,
    DELETE_ACCOUNT_REQUEST,
    deleteAccountError,
    deleteAccountSuccess,
    USER_SWITCH_SPACE_REQUEST,
    PASSWORD_CHANGE_REQUEST,
    ACCOUNT_OPTIONS_SAVE_REQUEST,
    userSwitchSpaceError,
    passwordChangeError,
    passwordChangeSuccess,
    accountOptionsSaveError,
    accountOptionsSaveSuccess,
    UPDATE_USER_NAME_REQUEST,
    updateUserNameSuccess,
    updateUserNameError,
    ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST,
    enrollMultiFactorAuthenticationSuccess,
    enrollMultiFactorAuthenticationError,
    CHANGE_EMAIL_REQUEST,
    changeEmailSuccess, changeEmailError
} from 'src/actions/loggedInUser';
import { USER_SECURITY_LOAD_REQUEST, userSecurityLoadError, userSecurityLoadSuccess } from 'src/actions/userSecurity';
import {
    call, put, takeEvery
} from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { hideLoading } from 'react-redux-loading-bar';
import { loginUrl } from 'src/config';
import { reportError } from 'src/utils/reportError';
import { showNotification } from 'src/actions/notifications';
import { SubmissionError } from 'redux-form';
import { handleAuthorizedServerRequest, handleLogout } from 'src/sagas/utils';
import {
    parseAccountOptions, parseSecurity, parseUserForManagement
} from 'src/parsers';

function* userSwitchSpaceRequest(action) {
    const { selectedSpaceId, url } = action.payload;
    try {
        const serverRequest = createServerRequest({ selectedSpaceId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/switch-space');
        if (response) {
            yield put(hideLoading());
            yield call(() => { window.location = !url ? '/' : url; });
        }
        if (serverError) {
            yield put(showNotification(serverError.message, 'error'));
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(userSwitchSpaceError(applicationOrServerError));
        yield put(showNotification('Switching to the selected space failed.', 'error'));
    }
}

function* deleteAccountRequest(action) {
    const { payload } = action;
    const { userId } = payload;
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/delete-account');
        if (response) {
            yield put(deleteAccountSuccess(userId));
            yield put(showNotification('Account deleted '));
            yield call(() => { window.location = loginUrl; });
        }
        if (serverError) {
            yield put(deleteAccountError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(deleteAccountError(new SubmissionError({ _error: applicationError })));
    }
}

function* addFirstAppStartedTimeRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-first-app-started-time');

        if (response) {
            const { firstAppStartedTime } = response.jsonData;
            yield put(addFirstAppStartedTimeSuccess(firstAppStartedTime));
        }

        if (serverError) {
            yield put(addFirstAppStartedTimeError(serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(addFirstAppStartedTimeError(applicationError));
    }
}

function* passwordChangeRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/change-password');
        if (response) {
            yield put(passwordChangeSuccess());
            yield put(showNotification('Please check your mailbox for further instructions to change the password.'));
        }
        if (serverError) {
            yield put(passwordChangeError(serverError));
            yield put(showNotification('Something went wrong, please try again.', 'error'));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(passwordChangeError(applicationError));
    }
}

function* accountOptionsSaveRequest(action) {
    const { payload } = action;
    const { accountOptions } = payload;
    const accountOptionsToChange = {
        csvSeparator: accountOptions.csvSeparator,
        thousandSeparator: accountOptions.numberFormat.charAt(0),
        decimalSeparator: accountOptions.numberFormat.charAt(1),
        exportEncoding: accountOptions.exportEncoding,
        timezone: accountOptions.timezone,
        dateFormat: accountOptions.dateFormat,
        weekDefinition: accountOptions.weekDefinition
    };
    try {
        const serverRequest = createServerRequest(accountOptionsToChange);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/save-account-options');
        if (response) {
            const { jsonData } = response;
            const parsedAccountOptions = parseAccountOptions(jsonData);
            yield put(accountOptionsSaveSuccess(parsedAccountOptions));
            yield put(showNotification('Your account settings were successfully updated.'));
        }
        if (serverError) {
            yield put(accountOptionsSaveError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(accountOptionsSaveError(new SubmissionError({ _error: applicationError })));
    }
}

function* userSecurityLoadRequest(action) {
    const { payload } = action;

    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-user-security');
        if (response) {
            const { jsonData } = response;
            yield put(userSecurityLoadSuccess(payload.userId, parseSecurity(jsonData)));
        }
        if (serverError) {
            yield put(userSecurityLoadError(payload.userId, serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(userSecurityLoadError(payload.userId, applicationError));
    }
}

function* updateUserNameRequest(action) {
    const { type, name } = action.payload;
    try {
        const serverRequest = createServerRequest({
            type, name
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-user-name');
        if (response) {
            const { jsonData } = response;
            yield put(updateUserNameSuccess(type, parseUserForManagement(jsonData)));
            yield put(showNotification('Name updated.'));
        }
        if (serverError) {
            yield put(updateUserNameError(type, serverError));
            yield put(showNotification('Name update failed.', 'error'));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(updateUserNameError(type, applicationError));
    }
}

function* enrollMultiFactorAuthenticationRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/enroll-multi-factor-authentication');
        if (response) {
            yield put(enrollMultiFactorAuthenticationSuccess());
            yield put(showNotification('An email to enroll for multi-factor authentication has been sent to you.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(enrollMultiFactorAuthenticationError());
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

function* changeEmailRequest(action) {
    const { payload } = action;

    try {
        const serverRequest = createServerRequest({ email: payload.email, userId: payload.userId, connection: payload.connection });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/change-email');
        if (response) {
            yield put(changeEmailSuccess());
            yield put(showNotification('Email has been changed. Please check your email for further instructions.'));
            yield handleLogout();
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(changeEmailError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

export default function* loggedInUserSaga() {
    yield takeEvery(USER_SWITCH_SPACE_REQUEST, userSwitchSpaceRequest);
    yield takeEvery(DELETE_ACCOUNT_REQUEST, deleteAccountRequest);
    yield takeEvery(ADD_FIRST_APP_STARTED_TIME_REQUEST, addFirstAppStartedTimeRequest);
    yield takeEvery(PASSWORD_CHANGE_REQUEST, passwordChangeRequest);
    yield takeEvery(ACCOUNT_OPTIONS_SAVE_REQUEST, accountOptionsSaveRequest);
    yield takeEvery(USER_SECURITY_LOAD_REQUEST, userSecurityLoadRequest);
    yield takeEvery(UPDATE_USER_NAME_REQUEST, updateUserNameRequest);
    yield takeEvery(ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST, enrollMultiFactorAuthenticationRequest);
    yield takeEvery(CHANGE_EMAIL_REQUEST, changeEmailRequest);
}
