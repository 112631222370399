import Button from 'src/components/buttons/Button';
import React, { useEffect } from 'react';
import appErrorStyles from 'src/stylesheets/appError.scss';
import classnames from 'classnames';
import quintlyLogo from 'src/resources/images/facelift-logo.svg';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/confirmAccountError.scss';

const ConfirmAccountError = ({ logout }) => {
    useEffect(() => {
        // remove all parameters from url because otherwise browser reload triggers auth0 error
        window.history.replaceState(null, null, window.location.pathname);
    },
    []);
    return (
        <div className={classnames(appErrorStyles.appError, styles.background)}>
            <div className={classnames(appErrorStyles.feedback, styles.wrapper)}>
                <img src={quintlyLogo} className={styles.logo} />
                <p className={styles.headline}>Verify your email address</p>
                <p className={styles.text}>To confirm your email address, <b>please click on the link</b> in the email we sent you.</p>
                <Button className={styles.button} onClick={logout} label="Logout" />
            </div>
        </div>
    );
};

ConfirmAccountError.propTypes = {
    logout: PropTypes.func.isRequired
};

export default ConfirmAccountError;
