import {
    CREATE_USER_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    TRANSFER_AND_DELETE_SUCCESS,
    RESEND_USER_INVITATION_SUCCESS,
    RESEND_USER_INVITATION_ERROR,
    RESEND_USER_INVITATION_REQUEST,
    CREATE_ONBOARDING_USER_SUCCESS
} from 'src/actions/users';
import { ADMIN_SETTINGS_GET_SETTINGS_SUCCESS } from 'src/actions/adminSettings';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';
import {
    UPDATE_USER_NAME_ERROR, UPDATE_USER_NAME_REQUEST, UPDATE_USER_NAME_SUCCESS, USER_LOGGED_IN
} from 'src/actions/loggedInUser';

export function allIds(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { users } = payload;
            const combinedState = [...state, ...Object.keys(users)];
            return combinedState.filter((userId, position, allUserIds) => allUserIds.indexOf(userId) === position);
        }
        case DELETE_USER_SUCCESS: {
            const { userId } = payload;
            return state.filter((value) => value !== userId);
        }
        case TRANSFER_AND_DELETE_SUCCESS: {
            const { deleteUserId } = payload;
            return state.filter((value) => value !== deleteUserId);
        }
        case CREATE_USER_SUCCESS:
        case CREATE_ONBOARDING_USER_SUCCESS: {
            const { user } = payload;
            const userId = user.id;
            return [...state, userId];
        }
        case USER_LOGGED_IN: {
            const { loggedInUser } = payload;
            return [...state, loggedInUser.user.id];
        }
        default:
            return state;
    }
}

export function byId(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { users } = payload;
            return Object.assign({}, state, users);
        }
        case RESEND_USER_INVITATION_SUCCESS:
        case UPDATE_USER_NAME_SUCCESS:
        case UPDATE_USER_SUCCESS: {
            const { user } = payload;
            return Object.assign({}, state, { [user.id]: user });
        }
        case CREATE_USER_SUCCESS:
        case CREATE_ONBOARDING_USER_SUCCESS: {
            const { user } = payload;
            const usersWithNewUser = {};
            usersWithNewUser[user.id] = user;
            return Object.assign({}, state, usersWithNewUser);
        }
        case DELETE_USER_SUCCESS: {
            const { userId } = payload;
            return _omit(state, userId);
        }
        case TRANSFER_AND_DELETE_SUCCESS: {
            const { deleteUserId } = payload;
            return _omit(state, deleteUserId);
        }
        case USER_LOGGED_IN: {
            const { loggedInUser } = payload;
            return Object.assign({}, state, { [loggedInUser.user.id]: loggedInUser.user });
        }
        default:
            return state;
    }
}

const asyncStatesByAction = combineReducers({
    delete: createAsyncStatesReducerForAction(
        (payload) => payload.userId,
        [DELETE_USER_REQUEST],
        [DELETE_USER_SUCCESS, DELETE_USER_ERROR]
    ),
    updateUserName: createAsyncStatesReducerForAction(
        (payload) => payload.type,
        [UPDATE_USER_NAME_REQUEST],
        [UPDATE_USER_NAME_SUCCESS, UPDATE_USER_NAME_ERROR]
    ),
    resendUserInvitation: createAsyncStatesReducerForAction(
        (payload) => payload.userId,
        [RESEND_USER_INVITATION_REQUEST],
        [RESEND_USER_INVITATION_SUCCESS, RESEND_USER_INVITATION_ERROR]
    )
});

export const users = combineReducers({
    allIds,
    byId,
    asyncStatesByAction
});
