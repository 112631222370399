import createServerRequest from 'src/requestHandling/createServerRequest';

export const createTarget = ({ authenticator }) => (events) => {
    events.forEach((event) => {
        switch (event.event) {
            case 'custom': {
                const serverRequest = createServerRequest({
                    name: event.eventName,
                    params: event.params,
                    version: 'malibu'
                });
                authenticator.handleAuthorizedServerRequest(serverRequest, '/client-index/log-event').catch(() => {});
                break;
            }
            case 'virtualPageView': {
                const serverRequest = createServerRequest({
                    path: event.page,
                    version: 'malibu'
                });
                authenticator.handleAuthorizedServerRequest(serverRequest, '/client-index/log-page-view').catch(() => {});
                break;
            }
            default:
                break;
        }
    });
};
