import {
    PROFILE_SEARCH_FILTER_CHANGE,
    ACCOUNT_SEARCH_RESET,
    PROFILE_SEARCH_RESET_INPUT,
    PROFILE_SEARCH_BY_KEYWORD_REQUEST,
    PROFILE_SEARCH_BY_URLS_REQUEST,
    PROFILE_SEARCH_BY_URLS_ERROR,
    PROFILE_SEARCH_BY_URLS_SUCCESS,
    PROFILE_SEARCH_BY_KEYWORD_ERROR,
    PROFILE_SEARCH_BY_KEYWORD_SUCCESS,
    PROFILE_SEARCH_SUCCESS,
    PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS,
    PROFILE_SEARCH_BY_AUTH_PROFILES_ERROR,
    PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_ERROR,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_SUCCESS,
    SOCIAL_NETWORK_SEARCH_RESET,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR, SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS
} from 'src/actions/profileSearch';
import { combineReducers } from 'redux';
import _uniq from 'lodash/uniq';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

export const OUTPUT_INITIAL_STATE = {
    keyword: '',
    profileIds: [],
    hints: [],
    errors: [],
    failedLinks: [],
    outOfProfileLimitLinks: [],
    resultType: 'query'
};

const defaultFilter = 'all';

function input(state = { keyword: '' }, action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SEARCH_BY_KEYWORD_REQUEST:
        case PROFILE_SEARCH_BY_URLS_REQUEST: {
            const { profileSearchInput } = payload;
            return { keyword: profileSearchInput };
        }
        case ACCOUNT_SEARCH_RESET:
        case PROFILE_SEARCH_RESET_INPUT: {
            return { keyword: '' };
        }
        default:
            return state;
    }
}

function output(state = OUTPUT_INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SEARCH_BY_KEYWORD_REQUEST:
        case PROFILE_SEARCH_BY_URLS_REQUEST: {
            const { profileSearchInput } = payload;
            return Object.assign({}, OUTPUT_INITIAL_STATE, {
                keyword: profileSearchInput,
                profileIds: []
            });
        }
        case PROFILE_SEARCH_BY_KEYWORD_SUCCESS: {
            const {
                profileSearchInput,
                searchResults,
                resultType,
                hints,
                errors
            } = payload;
            return Object.assign({}, state, {
                keyword: profileSearchInput,
                profileIds: Object.keys(searchResults),
                resultType,
                hints,
                errors
            });
        }
        case PROFILE_SEARCH_BY_URLS_SUCCESS: {
            const { failedLinks, outOfProfileLimitLinks, hints } = payload;
            return Object.assign({}, state, { failedLinks, outOfProfileLimitLinks, hints });
        }
        case PROFILE_SEARCH_SUCCESS: {
            const { searchResults } = payload;
            const { profileIds } = state;
            return Object.assign({}, state, {
                profileIds: _uniq([...profileIds, ...Object.keys(searchResults)])
            });
        }
        case PROFILE_SEARCH_RESET_INPUT:
        case ACCOUNT_SEARCH_RESET: {
            return OUTPUT_INITIAL_STATE;
        }
        default:
            return state;
    }
}

function isPending(state = false, action) {
    const { type } = action;
    switch (type) {
        case PROFILE_SEARCH_BY_KEYWORD_REQUEST:
        case PROFILE_SEARCH_BY_URLS_REQUEST:
            return true;
        case PROFILE_SEARCH_BY_KEYWORD_SUCCESS:
        case PROFILE_SEARCH_BY_KEYWORD_ERROR:
        case PROFILE_SEARCH_BY_URLS_SUCCESS:
        case PROFILE_SEARCH_BY_URLS_ERROR:
            return false;
        default:
            return state;
    }
}

function filter(state = defaultFilter, action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SEARCH_FILTER_CHANGE: {
            const { filterBy } = payload;
            return filterBy;
        }
        case ACCOUNT_SEARCH_RESET:
        case PROFILE_SEARCH_RESET_INPUT: {
            return defaultFilter;
        }
        default:
            return state;
    }
}

const inputBoxSearch = combineReducers({
    input,
    output,
    isPending,
    filter
});

const authProfileSearchOutputState = {
    profileIds: []
};

const authProfileSearchOutput = (state = authProfileSearchOutputState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS: {
            const { searchResults } = payload;
            const { profileIds } = state;
            return Object.assign({}, state, {
                profileIds: _uniq([...profileIds, ...Object.keys(searchResults)])
            });
        }
        case ACCOUNT_SEARCH_RESET: {
            return Object.assign({}, state, {
                profileIds: []
            });
        }
        default:
            return state;
    }
};

const authProfileSearch = combineReducers({
    search: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST],
        [PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS],
        [PROFILE_SEARCH_BY_AUTH_PROFILES_ERROR]
    ),
    authProfileSearchOutput
});

const defaultState = {
    network: '',
    loading: false,
    error: null,
    data: null
};

const authenticatedSearch = (state = defaultState, action) => {
    switch (action.type) {
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST:
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST: {
            const { payload } = action;
            return {
                network: payload.network,
                loading: true,
                error: null,
                data: null,
            };
        }
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_ERROR:
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR: {
            const { payload } = action;
            return Object.assign({}, state, {
                loading: false,
                error: payload.error,
                data: null
            });
        }
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_SUCCESS:
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS: {
            const { payload } = action;
            const { searchResults } = payload;
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: Object.keys(searchResults)
            });
        }
        case SOCIAL_NETWORK_SEARCH_RESET: {
            return defaultState;
        }
        default:
            return state;
    }
};

const isAuthenticatedSearchResultPage = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST:
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST:
            return true;
        case SOCIAL_NETWORK_SEARCH_RESET:
            return false;
        default:
            return state;
    }
};

function searchedProfilesById(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_SUCCESS:
        case SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS:
        case PROFILE_SEARCH_BY_KEYWORD_SUCCESS:
        case PROFILE_SEARCH_SUCCESS:
        case PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS: {
            const { searchResults } = payload;
            return Object.assign({}, state, searchResults);
        }
        case ACCOUNT_SEARCH_RESET: {
            return {};
        }
        default:
            return state;
    }
}

export const profileSearch = combineReducers({
    searchedProfilesById,
    inputBoxSearch,
    authenticatedSearch,
    isAuthenticatedSearchResultPage,
    authProfileSearch
});
