import {
    BULK_PROFILES_USE_CASES_UPDATE_REQUEST,
    bulkProfilesUseCasesUpdateError,
    bulkProfilesUseCasesUpdateSuccess,
    PROFILE_USE_CASES_SET_REQUEST,
    profileUseCasesSetError,
    profileUseCasesSetSuccess,
    removeProfileUseCaseRequest,
    removeProfileUseCaseError,
    removeProfileUseCaseSuccess,
} from 'src/actions/profileUseCases';
import { call, put, takeEvery } from 'redux-saga/effects';
import { profilesHealthStatusGetRequest } from 'src/actions/profileHealth';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { modalHideActivateBulkProfileUseCases } from 'src/actions/overlays';
import { showNotification } from 'src/actions/notifications';
import { accountUseCasesSetRequest, bulkAccountUseCasesUpdateRequest } from 'src/sagas/accountUseCases';

import { SubmissionError } from 'redux-form';

const selectProfileById = makeSelectProfileById();
const getFailedTwitterAnalyticsErrorMessage = (count) => `We could not activate ${count} X Tweet Analytics use case${count > 1 ? 's' : ''}, because you have reached the total limit of X analytics profiles allocated to this space. Please reach out to support@facelift-bbt.com to upgrade your account.`;

const updateProfileUseCaseEndpoint = '/client-index/update-profiles-use-cases';

function* profileUseCasesSetRequest(action) {
    const { formValues } = action.payload;
    const { accountId } = formValues;
    try {
        const { response, serverError } = yield call(accountUseCasesSetRequest, formValues, updateProfileUseCaseEndpoint, selectProfileById);
        if (response) {
            const { notCreatedTwitterUseCasesCount } = response.jsonData;
            yield put(profileUseCasesSetSuccess(accountId));
            const notificationType = notCreatedTwitterUseCasesCount > 0 ? 'error' : 'success';
            const failedMessage = notCreatedTwitterUseCasesCount > 0 ? getFailedTwitterAnalyticsErrorMessage(notCreatedTwitterUseCasesCount) : '';
            const finalMessage = failedMessage ? `${failedMessage}` : 'The use cases for the selected profile was successfully updated.';
            yield put(showNotification(finalMessage, notificationType));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(profileUseCasesSetError(new SubmissionError({ _error: applicationError })));
    }
}

function* bulkProfileUseCasesUpdateRequest(action) {
    const { payload } = action;
    const { checkedAndUncheckedUseCasesPerProfile } = payload;
    try {
        const { accountIds, allNotCreatedTwitterUseCasesCount } = yield call(bulkAccountUseCasesUpdateRequest, checkedAndUncheckedUseCasesPerProfile, updateProfileUseCaseEndpoint);
        yield put(bulkProfilesUseCasesUpdateSuccess());
        yield put(modalHideActivateBulkProfileUseCases());
        const notificationType = allNotCreatedTwitterUseCasesCount > 0 ? 'warning' : 'success';
        const message = allNotCreatedTwitterUseCasesCount > 0 ? getFailedTwitterAnalyticsErrorMessage(allNotCreatedTwitterUseCasesCount) : '';
        const finalMessage = message ? `Some use cases for the selected profiles were successfully updated, but ${message}` : 'The use cases for the selected profiles were successfully updated.';
        yield put(showNotification(finalMessage, notificationType));
        yield put(profilesHealthStatusGetRequest(accountIds));
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(bulkProfilesUseCasesUpdateError(applicationError.message));
    }
}

function* removeProfileUseCaseRequestAction(action) {
    const { payload } = action;
    const { accountId, useCaseId } = payload;
    const params = {
        accountId,
        [`${useCaseId}_useCase`]: false
    };
    try {
        const { response, serverError } = yield call(accountUseCasesSetRequest, params, updateProfileUseCaseEndpoint, selectProfileById);
        if (response) {
            yield put(removeProfileUseCaseSuccess(accountId, useCaseId));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(removeProfileUseCaseError(accountId, useCaseId, applicationError.message));
    }
}

export default function* profileUseCasesSaga() {
    yield takeEvery(PROFILE_USE_CASES_SET_REQUEST, profileUseCasesSetRequest);
    yield takeEvery(BULK_PROFILES_USE_CASES_UPDATE_REQUEST, bulkProfileUseCasesUpdateRequest);
    yield takeEvery(removeProfileUseCaseRequest.type, removeProfileUseCaseRequestAction);
}
