import {
    PROFILE_EVENTS_GET_ERROR,
    PROFILE_EVENTS_GET_REQUEST,
    PROFILE_EVENTS_GET_SUCCESS
} from 'src/actions/profileEvents';
import { combineReducers } from 'redux';
import _uniq from 'lodash/uniq';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_EVENTS_GET_SUCCESS: {
            const { profileEvents } = payload;
            return Object.assign({}, state, profileEvents);
        }
        default: {
            return state;
        }
    }
};

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_EVENTS_GET_SUCCESS: {
            const { profileEvents } = payload;
            return _uniq([...state, ...Object.keys(profileEvents)]);
        }
        default: {
            return state;
        }
    }
};

const asyncStates = combineReducers({
    get: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.profileId,
        [PROFILE_EVENTS_GET_REQUEST],
        [PROFILE_EVENTS_GET_SUCCESS],
        [PROFILE_EVENTS_GET_ERROR]
    )
});

export const profileEvents = combineReducers({
    byId,
    allIds,
    asyncStates
});
