import Auth0LoginErrorHandler from 'src/components/auth0/Auth0LoginErrorHandler';
import Auth0LoginRedirect from 'src/components/auth0/Auth0LoginRedirect';
import LoadingScreen from 'src/components/LoadingScreen';
import Auth0UserChangeDetectedModal from 'src/components/modals/Auth0UserChangeDetectedModal';
import React, { useRef, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import _has from 'lodash/has';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const useHasChanged = (val, property = 'sub') => {
    const prevVal = usePrevious(val);
    if (_has(val, property) && _has(prevVal, property)) {
        return prevVal[property] !== val[property];
    }
    return false;
};

const Auth0LoginHandler = ({ renderTool }) => {
    const {
        isLoading,
        isAuthenticated,
        error,
        loginWithRedirect,
        logout,
        user
    } = useAuth0();

    // If we detect a user change in the background with a different email, we show a modal to the user
    const [showUserChangedModal, setShowUserChangedModal] = useState(false);
    const userHasChanged = useHasChanged(user, 'email');

    useEffect(() => {
        if (userHasChanged) {
            setShowUserChangedModal(true);
        }
    });

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <Auth0LoginErrorHandler
              error={error}
              logout={logout}
            />
        );
    }

    if (isAuthenticated) {
        return (
            <>
                {
                    renderTool()
                }
                {
                   showUserChangedModal && <Auth0UserChangeDetectedModal enforceFocus />
                }
            </>
        );
    }

    return (
        <Auth0LoginRedirect loginWithRedirect={loginWithRedirect} />
    );
};

Auth0LoginHandler.propTypes = {
    renderTool: PropTypes.func.isRequired
};

export default Auth0LoginHandler;
