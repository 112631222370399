import { combineReducers } from 'redux';
import {
    PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST,
    PROFILE_GET_AUDIENCE_DATA_OVERVIEW_ERROR,
    PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS
} from 'src/actions/profileAudienceData';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

const audienceById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS: {
            const { audienceData } = payload;
            if (audienceData !== null) {
                return Object.assign({}, state, { [audienceData.profileId]: audienceData });
            }
            return state;
        }
        default:
            return state;
    }
};

const warningByProfileId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS: {
            const { profileId, warning } = payload;
            if (warning !== null) {
                return Object.assign({}, state, { [profileId]: warning });
            }
            return state;
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    getAudienceData: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.profileId,
        [PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST],
        [PROFILE_GET_AUDIENCE_DATA_OVERVIEW_SUCCESS],
        [PROFILE_GET_AUDIENCE_DATA_OVERVIEW_ERROR]
    )
});

export const profileAudienceData = combineReducers({
    audienceById,
    warningByProfileId,
    asyncStates
});
