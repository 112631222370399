import {
    ADMIN_SETTINGS_GET_SETTINGS_ERROR,
    ADMIN_SETTINGS_GET_SETTINGS_REQUEST,
    ADMIN_SETTINGS_GET_SETTINGS_SUCCESS,
    ACCOUNT_GET_DATA_SUCCESS,
    ACCOUNT_GET_DATA_ERROR,
    ACCOUNT_GET_DATA_REQUEST,
    BILLING_LINK_GENERATE_REQUEST,
    BILLING_LINK_GENERATE_ERROR,
    BILLING_LINK_GENERATE_SUCCESS
} from 'src/actions/adminSettings';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates, createAsyncStatesReducerForAction } from 'src/reducers/utils';
import { USER_INVOICES_REQUEST, USER_INVOICES_ERROR, USER_INVOICES_SUCCESS } from 'src/actions/invoices';

const packageInfo = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { accountData } = payload;
            return Object.assign({}, state, accountData);
        }
        default:
            return state;
    }
};

export const packageStatus = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { packageStatus: packageStatusFromPayload } = payload;
            return Object.assign({}, state, packageStatusFromPayload);
        }
        default:
            return state;
    }
};

const initialValues = {
    name: '',
    person: '',
    address: '',
    zip: '',
    city: '',
    country: 'DE',
    vatId: ''
};

export const billingAddress = (state = initialValues, action) => {
    const { payload, type } = action;
    switch (type) {
        case ACCOUNT_GET_DATA_SUCCESS: {
            const { billingAddress: billingAddressFromPayload } = payload.results;
            return Object.assign({}, state, billingAddressFromPayload);
        }
        default:
            return state;
    }
};

const creditCardInit = {
    expMonth: '',
    expYear: '',
    expired: false,
    last4: ''
};

export const creditCard = (state = creditCardInit, action) => {
    const { payload, type } = action;
    switch (type) {
        case ACCOUNT_GET_DATA_SUCCESS: {
            const { creditCard: creditCardFromPayload } = payload.results;
            if (creditCardFromPayload === null) {
                return state;
            }
            return Object.assign({}, state, creditCardFromPayload);
        }
        default:
            return state;
    }
};

const defaultState = {
    isAccountDataLoading: false,
    isInvoiceLoading: false
};

export function billingAndInvoiceLoadingState(state = defaultState, action) {
    const { type } = action;
    switch (type) {
        case ACCOUNT_GET_DATA_REQUEST: {
            return Object.assign({}, state, { isAccountDataLoading: true });
        }
        case ACCOUNT_GET_DATA_SUCCESS:
        case ACCOUNT_GET_DATA_ERROR: {
            return Object.assign({}, state, { isAccountDataLoading: false });
        }
        case USER_INVOICES_REQUEST: {
            return Object.assign({}, state, { isInvoiceLoading: true });
        }
        case USER_INVOICES_SUCCESS:
        case USER_INVOICES_ERROR: {
            return Object.assign({}, state, { isInvoiceLoading: false });
        }
        default:
            return state;
    }
}

export const asyncStates = combineReducers({
    adminSettings: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [ADMIN_SETTINGS_GET_SETTINGS_REQUEST],
        [ADMIN_SETTINGS_GET_SETTINGS_SUCCESS],
        [ADMIN_SETTINGS_GET_SETTINGS_ERROR]
    ),
    accountData: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [ACCOUNT_GET_DATA_REQUEST],
        [ACCOUNT_GET_DATA_SUCCESS],
        [ACCOUNT_GET_DATA_ERROR]
    ),
    billingAndInvoiceLoadingState,
    generateBillingLink: createAsyncStatesReducerForAction(
        () => 'state',
        [BILLING_LINK_GENERATE_REQUEST],
        [BILLING_LINK_GENERATE_SUCCESS, BILLING_LINK_GENERATE_ERROR]
    )
});

const apiInitialState = {
    enabled: false,
    secret: null
};

export const apiAccess = (state = apiInitialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { apiAccess: apiAccessFromPayload } = payload;
            return Object.assign({}, state, apiAccessFromPayload);
        }
        default:
            return state;
    }
};

export const adminSettings = combineReducers({
    billingAddress,
    creditCard,
    packageInfo,
    packageStatus,
    apiAccess,
    asyncStates
});
