import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/userIcon.scss';

const UserIcon = (props) => {
    const {
        iconUrl,
        size
    } = props;

    return (
        <div
          className={styles.icon}
          style={{ backgroundImage: `url('${iconUrl}')`, width: size, height: size }}
        />
    );
};

UserIcon.propTypes = {
    iconUrl: PropTypes.string.isRequired,
    size: PropTypes.number
};

UserIcon.defaultProps = {
    size: 36
};

export default UserIcon;
