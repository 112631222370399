import _omit from 'lodash/omit';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { accountEntitiesGetRequest } from 'src/sagas/accounts';
import { showTypedNotification } from 'src/actions/notifications';
import { getLabelAndFinalState } from 'src/utils/accountInsights';
import _split from 'lodash/split';
import _parseInt from 'lodash/parseInt';
import {
    all, call, put, select
} from 'redux-saga/effects';

const getUseCaseStates = (useCases) => {
    const checkedUseCaseIds = [];
    const unCheckedUseCaseIds = [];
    Object.keys(useCases).forEach((useCase) => {
        const value = useCases[useCase];
        const id = _parseInt(_split(useCase, '_')[0]);
        if (value) {
            checkedUseCaseIds.push(id);
        } else {
            unCheckedUseCaseIds.push(id);
        }
    });
    return {
        checkedUseCaseIds,
        unCheckedUseCaseIds
    };
};

export function* accountUseCasesSetRequest(formValues, endpoint, accountSelector) {
    const { accountId, showGoToAuthenticationsButton = false } = formValues;
    const useCaseFormValues = _omit(formValues, 'accountId', 'showGoToAuthenticationsButton');
    const { checkedUseCaseIds, unCheckedUseCaseIds } = getUseCaseStates(useCaseFormValues);
    const params = {
        [accountId]: {
            checkedUseCaseIds,
            unCheckedUseCaseIds
        }
    };
    const serverRequest = createServerRequest({ checkedAndUncheckedUseCasesPerAccount: JSON.stringify(params) });
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endpoint);
    if (response) {
        // update all account use case entities
        yield call(accountEntitiesGetRequest);
        // check the state of the profile
        const account = yield select(accountSelector, accountId);
        const { useCases: requiredUseCases, accountUseCasesAuthUsers } = account;
        const { finalState } = getLabelAndFinalState(requiredUseCases, accountUseCasesAuthUsers);
        if (finalState !== 'ok') {
            yield put(showTypedNotification('UnfulfilledAccountUseCases', { showGoToAuthenticationsButton }, account.id, 'warning', 8));
        }
        return { response };
    }
    return { serverError };
}

const chunks = (checkedAndUncheckedUseCasesPerAccount, size) => {
    const results = [];
    const allAccountIds = Object.keys(checkedAndUncheckedUseCasesPerAccount);
    while (allAccountIds.length) {
        const currentAccountIds = allAccountIds.splice(0, size);
        let accounts = {};
        currentAccountIds.forEach((accountId) => {
            accounts = Object.assign(accounts, { [accountId]: checkedAndUncheckedUseCasesPerAccount[accountId] });
        });
        results.push(accounts);
    }
    return results;
};

function* bulkAccountUseCasesUpdatingRequest(serverRequest, accountIds, endpoint) {
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endpoint);
    return {
        response, serverError, accountIds
    };
}

const aggregateResponses = (results) => {
    let allNotCreatedTwitterUseCasesCount = 0;
    let failedAccountIds = [];
    let failedMessage = '';

    results.forEach((result) => {
        const { response, serverError, accountIds } = result;
        if (response) {
            const { notCreatedTwitterUseCasesCount = 0 } = response.jsonData;
            allNotCreatedTwitterUseCasesCount += notCreatedTwitterUseCasesCount;
        }
        if (serverError) {
            failedAccountIds = failedAccountIds.concat(accountIds);
            failedMessage = serverError.message;
        }
    });
    return {
        allNotCreatedTwitterUseCasesCount,
        failedAccountIds,
        failedMessage
    };
};

export function* bulkAccountUseCasesUpdateRequest(checkedAndUncheckedUseCasesPerAccount, endpoint) {
    const chunksOfCheckedAndUncheckedUseCasesPerAccount = chunks(checkedAndUncheckedUseCasesPerAccount, 100);
    const accountUseCaseUpdateRequests = chunksOfCheckedAndUncheckedUseCasesPerAccount.map((chunkOfAccounts) => call(bulkAccountUseCasesUpdatingRequest, createServerRequest({ checkedAndUncheckedUseCasesPerAccount: JSON.stringify(chunkOfAccounts) }), Object.keys(chunkOfAccounts), endpoint));
    const collectedResponses = yield all(accountUseCaseUpdateRequests);
    const {
        allNotCreatedTwitterUseCasesCount,
        failedAccountIds,
        failedMessage
    } = aggregateResponses(collectedResponses);
    const accountIds = Object.keys(checkedAndUncheckedUseCasesPerAccount);
    if (failedAccountIds.length === accountIds.length) {
        throw new Error(`something went wrong, ${failedMessage}`);
    }

    // update all profile use case entities
    yield call(accountEntitiesGetRequest);

    return {
        accountIds,
        failedAccountIds,
        allNotCreatedTwitterUseCasesCount
    };
}
