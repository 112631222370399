import md5 from 'md5';

export const getUserGravatars = (email) => {
    const hash = md5(email.toLowerCase());
    return {
        gravatar35px: `https://www.gravatar.com/avatar/${hash}?s=35&d=mm`,
        gravatar80px: `https://www.gravatar.com/avatar/${hash}?s=80&d=mm`,
        gravatar100px: `https://www.gravatar.com/avatar/${hash}?s=100&d=mm`
    };
};

export const getAgentModeText = (user, loggedInUser) => `Agent mode - Logged in as ${user.email} impersonating ${loggedInUser.email} - Be careful as changes are affecting the client's account.`;
