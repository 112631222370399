import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import {
    GROUP_ADD_REQUEST,
    GROUP_DELETE_REQUEST,
    GROUP_EDIT_REQUEST,
    GROUP_FORCE_DELETE_REQUEST,
    groupAddError,
    groupAddSuccess,
    groupDeleteError,
    groupDeleteSuccess,
    groupEditError,
    groupEditSuccess,
    groupForceDeleteError,
    groupForceDeleteSuccess
} from 'src/actions/groups';
import {
    modalsHideForceDeleteGroupModal,
    modalsHideGroupCreate,
    modalsHideGroupDelete,
    modalsShowForceDeleteGroupModal
} from 'src/actions/overlays';
import { showGroupAddSuccessNotification, showNotification } from 'src/actions/notifications';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    parseGroup,
    convertArrayElementsToString,
    getForceOperationalEntityIds,
    getForceOperationalEntityInfos
} from 'src/parsers';
import { reportError } from 'src/utils/reportError';
import { SubmissionError } from 'redux-form';
import _parseInt from 'lodash/parseInt';
import { makeSelectGroupsByIds } from 'src/selectors/groups';

const selectGroupsByIds = makeSelectGroupsByIds();

function* groupAddRequest(action) {
    const { name } = action.payload;
    try {
        const serverRequest = createServerRequest({ name });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-group');
        if (response) {
            const { jsonData } = response;

            const parsedGroup = parseGroup(jsonData);
            yield put(groupAddSuccess(parsedGroup));
            yield put(modalsHideGroupCreate());
            yield put(showGroupAddSuccessNotification(parsedGroup.id, parsedGroup.name));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(showNotification(applicationOrServerError.message, 'error'));
        yield put(groupAddError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

function* groupEditRequest(action) {
    const { id, newName } = action.payload;

    try {
        const serverRequest = createServerRequest({ id, name: newName });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/rename-group');
        if (response) {
            const { jsonData } = response;
            const parsedGroup = parseGroup(jsonData);
            yield put(groupEditSuccess(parsedGroup.id, parsedGroup));
            yield put(showNotification('Your group was successfully edited.'));
        }
        if (serverError) {
            if (serverError.errorType === 'groupNameCharactersLimitReached') {
                serverError.title = 'Group name too long!';
                yield put(groupEditError(id, serverError));
                yield put(showNotification('We are sorry, editing your group failed.', 'error'));
            } else {
                throw serverError;
            }
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(groupEditError(id, applicationOrServerError));
        yield put(showNotification('We are sorry, editing your group failed.', 'error'));
    }
}

function* groupDelete(requestParams, url) {
    const serverRequest = createServerRequest(requestParams);
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, url);
        if (response) {
            return {
                response: response.jsonData
            };
        }
        if (serverError) {
            throw serverError;
        }
        return {
            serverError: {
                type: 'unknownError',
                message: 'We are sorry, deleting your group failed.'
            }
        };
    } catch (serverError) {
        return {
            serverError
        };
    }
}

function* getForceOperationalGroupInfos(forceOperationalGroupsInfo) {
    const forceOperationalGroupIds = getForceOperationalEntityIds(forceOperationalGroupsInfo);
    const forceOperationalGroups = yield select(selectGroupsByIds, forceOperationalGroupIds);
    const forceOperationalGroupInfos = getForceOperationalEntityInfos(forceOperationalGroups, forceOperationalGroupsInfo);
    return forceOperationalGroupInfos;
}

function* groupDeleteRequest(action) {
    const { payload } = action;
    const { groupIds } = payload;
    const requestParams = { groupIds: JSON.stringify(groupIds.map((id) => _parseInt(id))) };
    try {
        const { response, serverError } = yield call(groupDelete, requestParams, '/client-index/delete-group');
        if (response) {
            const { removedGroupIds, notRemovedGroupIds, forceRemovableGroupInfoArray } = response;
            yield put(modalsHideGroupDelete());
            const parsedGroupIds = convertArrayElementsToString(removedGroupIds);
            yield put(groupDeleteSuccess(parsedGroupIds));
            if (removedGroupIds.length > 0) {
                yield put(showNotification('Your group was successfully deleted.'));
            }
            if (forceRemovableGroupInfoArray.length > 0 || notRemovedGroupIds.length > 0) {
                const forceOperationalGroupInfos = yield getForceOperationalGroupInfos(forceRemovableGroupInfoArray);
                yield put(modalsShowForceDeleteGroupModal(forceOperationalGroupInfos, convertArrayElementsToString(notRemovedGroupIds)));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(groupDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your group failed.', 'error'));
        yield put(modalsHideGroupDelete());
    }
}

function* groupForceDeleteRequest(action) {
    const { payload } = action;
    const { groupIds } = payload;
    const requestParams = { groupIds: JSON.stringify(groupIds.map((groupId) => _parseInt(groupId))), forceDelete: true };
    try {
        const { response, serverError } = yield call(groupDelete, requestParams, '/client-index/force-delete-group');
        if (response) {
            const { removedGroupIds } = response;
            yield put(modalsHideForceDeleteGroupModal());
            yield put(groupForceDeleteSuccess(convertArrayElementsToString(removedGroupIds)));
            if (removedGroupIds.length > 0) {
                yield put(showNotification('Your group was successfully deleted.'));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(groupForceDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your group failed.', 'error'));
        yield put(modalsHideForceDeleteGroupModal());
    }
}

export default function* profileSagas() {
    yield takeEvery(GROUP_ADD_REQUEST, groupAddRequest);
    yield takeEvery(GROUP_EDIT_REQUEST, groupEditRequest);
    yield takeEvery(GROUP_DELETE_REQUEST, groupDeleteRequest);
    yield takeEvery(GROUP_FORCE_DELETE_REQUEST, groupForceDeleteRequest);
}
