import {
    CHECK_ALL_LIST_VALUES,
    CHECK_LIST_VALUES,
    INITIALIZE_LIST, REGISTER_LIST_ITEMS,
    TOGGLE_LIST_VALUE,
    UNCHECK_ALL_LIST_VALUES,
    UNCHECK_LIST_VALUE,
    UNCHECK_LIST_VALUES,
    UNREGISTER_LIST,
    UNREGISTER_LIST_ITEMS,
    UPDATE_LIST_VALUES
} from 'src/actions/lists';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _diff from 'lodash/difference';
import { setIn } from './utils';

export const lists = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case INITIALIZE_LIST: {
            const { items, listName } = payload;
            const itemsObject = {};
            items.forEach((item) => { Object.assign(itemsObject, { [item]: false }); });
            return setIn(state, `${listName}.items`, itemsObject);
        }

        case UNREGISTER_LIST: {
            const { listName } = payload;
            return _omit(state, listName);
        }

        case UNREGISTER_LIST_ITEMS: {
            const { listName, fieldNames } = payload;
            const prevItems = _get(state, `${listName}.items`, {});
            const newItems = _omit(prevItems, fieldNames);
            return setIn(state, `${listName}.items`, newItems);
        }

        case REGISTER_LIST_ITEMS: {
            const { listName, fieldNames, overRightPrevItems } = payload;
            const prevItems = _get(state, `${listName}.items`, {});
            const newListItems = {};
            fieldNames.forEach((fieldName) => Object.assign(newListItems, { [fieldName]: false }));
            const newItems = overRightPrevItems ? Object.assign({}, prevItems, newListItems) : Object.assign({}, newListItems, prevItems);
            return setIn(state, `${listName}.items`, newItems);
        }

        case CHECK_LIST_VALUES: {
            const { fieldNames, listName } = payload;
            if (_has(state, listName)) {
                const prevItems = _get(state, `${listName}.items`, {});
                const changedItems = {};
                fieldNames.forEach((fieldName) => {
                    if (_has(prevItems, fieldName)) {
                        Object.assign(changedItems, { [fieldName]: true });
                    }
                });
                const newItems = Object.assign({}, prevItems, changedItems);
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }

        case CHECK_ALL_LIST_VALUES: {
            const { listName } = payload;
            if (_has(state, listName)) {
                const newItems = {};
                const prevItems = _get(state, `${listName}.items`, {});
                Object.keys(prevItems).forEach((fieldName) => {
                    Object.assign(newItems, { [fieldName]: true });
                });
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }
        case UNCHECK_LIST_VALUE: {
            const { fieldName, listName } = payload;
            if (_has(state, listName)) {
                const prevItems = _get(state, `${listName}.items`, {});
                const newItems = Object.assign({}, prevItems, { [fieldName]: false });
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }

        case UNCHECK_LIST_VALUES: {
            const { fieldNames, listName } = payload;
            if (_has(state, listName)) {
                const prevItems = _get(state, `${listName}.items`, {});
                const changedItems = {};
                fieldNames.forEach((fieldName) => {
                    if (_has(prevItems, fieldName)) {
                        Object.assign(changedItems, { [fieldName]: false });
                    }
                });
                const newItems = Object.assign({}, prevItems, changedItems);
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }

        case UNCHECK_ALL_LIST_VALUES: {
            const { listName } = payload;
            if (_has(state, listName)) {
                const newItems = {};
                const prevItems = _get(state, `${listName}.items`, {});
                Object.keys(prevItems).forEach((fieldName) => { Object.assign(newItems, { [fieldName]: false }); });
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }

        case TOGGLE_LIST_VALUE: {
            const { fieldName, listName } = payload;
            if (_has(state, listName)) {
                const prevItems = _get(state, `${listName}.items`, {});
                const newItems = Object.assign({}, prevItems, { [fieldName]: !prevItems[fieldName] });
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }

        case UPDATE_LIST_VALUES: {
            const { fieldNames, listName } = payload;
            if (_has(state, listName)) {
                const prevItems = _get(state, `${listName}.items`, {});
                const prevItemsKeys = Object.keys(_get(state, `${listName}.items`, {}));
                const deleted = _diff(prevItemsKeys, fieldNames);
                const added = _diff(fieldNames, prevItemsKeys);
                const cleanedItems = _omit(prevItems, deleted);
                const newListItems = {};
                added.forEach((fieldName) => Object.assign(newListItems, { [fieldName]: false }));
                const newItems = Object.assign({}, cleanedItems, newListItems);
                return setIn(state, `${listName}.items`, newItems);
            }
            return state;
        }
        default:
            return state;
    }
};
