import { ADMIN_SETTINGS_GET_SETTINGS_SUCCESS } from 'src/actions/adminSettings';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { CREATE_SPACE_SUCCESS, UPDATE_SPACE_SUCCESS, DELETE_SPACE_SUCCESS } from 'src/actions/spaces';

export function allIds(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { loggedInUser } = payload;
            const { spaces } = loggedInUser;
            return Object.keys(spaces);
        }
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { spaces } = payload;
            const combinedState = [...state, ...Object.keys(spaces)];
            return combinedState.filter((spaceId, position, allSpaceIds) => allSpaceIds.indexOf(spaceId) === position);
        }
        case CREATE_SPACE_SUCCESS: {
            const { space } = payload;
            return [...state, space.id];
        }
        case DELETE_SPACE_SUCCESS: {
            const { spaceId } = payload;
            return state.filter((value) => value !== spaceId);
        }
        default:
            return state;
    }
}

export function byId(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { loggedInUser } = payload;
            const { spaces } = loggedInUser;
            return Object.assign({}, state, spaces);
        }
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS: {
            const { spaces } = payload;
            return Object.assign({}, state, spaces);
        }
        case CREATE_SPACE_SUCCESS:
        case UPDATE_SPACE_SUCCESS: {
            const { space } = payload;
            return Object.assign({}, state, { [space.id]: space });
        }
        case DELETE_SPACE_SUCCESS: {
            const { spaceId } = payload;
            return _omit(state, spaceId);
        }
        default:
            return state;
    }
}

export const spaces = combineReducers({
    allIds,
    byId
});
