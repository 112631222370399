import createServerRequest from 'src/requestHandling/createServerRequest';

export const createLogout = (handleAuthorizedServerRequest, logoutCallback) => async () => {
    const serverRequest = createServerRequest();
    try {
        await handleAuthorizedServerRequest(serverRequest, '/client-index/logout-impersonation');
    } catch (e) {
    }
    logoutCallback({ returnTo: window.location.origin });
};
