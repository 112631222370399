import { put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    PROFILE_HEALTH_METRIC_DATA_GET_REQUEST,
    PROFILES_HEALTH_STATUS_GET_REQUEST,
    PROFILE_HEALTH_STATUS_GET_REQUEST,
    profileHealthDataGetError,
    profileHealthDataGetSuccess,
    profileHealthStatusGetError,
    profileHealthStatusGetSuccess,
    profilesHealthStatusGetSuccess,
    profilesHealthStatusGetError,
    allProfileHealthStatusGetError,
    allProfileHealthStatusGetSuccess,
    tagProfileAsInstagramBusinessProfileSuccess,
    tagProfileAsInstagramBusinessProfileError,
    TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST,
    TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS
} from 'src/actions/profileHealth';
import {
    BULK_PROFILE_ADD_SUCCESS,
    ONBOARDING_BULK_PROFILE_ADD_SUCCESS,
    UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS,
    UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS
} from 'src/actions/profiles';
import { PROFILE_USE_CASES_SET_SUCCESS, removeProfileUseCaseSuccess } from 'src/actions/profileUseCases';
import { ACCOUNT_ENTITIES_GET_REQUEST } from 'src/actions/accounts';
import {
    FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
} from 'src/actions/graphAuthUsers';
import { authUserRemoveSuccess } from 'src/actions/authUsers';
import { YOUTUBE_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/youtubeAuthUsers';
import { TWITTER_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/twitterAuthUsers';
import { LINKED_IN_AUTH_USER_CONNECT_SUCCESS, LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/linkedInAuthUsers';
import _get from 'lodash/get';
import _parseInt from 'lodash/parseInt';
import { parseProfilesHealthStatus } from 'src/parsers';
import { showNotification } from 'src/actions/notifications';
import _has from 'lodash/has';
import { reportError } from 'src/utils/reportError';

function* profileHealthMetricDataGetRequest(action) {
    const { payload } = action;
    const { profileId } = payload;
    try {
        const serverRequest = createServerRequest({ profileId: _parseInt(profileId) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-profile-health-metric-data');
        if (response) {
            const { data } = response.jsonData;
            yield put(profileHealthDataGetSuccess(profileId, data));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(profileHealthDataGetError(profileId, applicationError));
    }
}

function* profileHealthStatusGetRequest(action) {
    const { payload } = action;
    const { profileId } = payload;
    try {
        const serverRequest = createServerRequest({ profileIds: JSON.stringify([profileId]) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-profiles-health-status');
        if (response) {
            const { profilesHealth } = response.jsonData;
            const parsedProfilesHealth = parseProfilesHealthStatus(profilesHealth);
            const status = _get(parsedProfilesHealth, [profileId], false);
            yield put(profileHealthStatusGetSuccess(profileId, status));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(profileHealthStatusGetError(profileId, applicationError));
    }
}

function* profilesHealthStatusGetRequest(action) {
    const { payload } = action;
    const { profileIds } = payload;

    try {
        const serverRequest = createServerRequest({ profileIds: JSON.stringify(profileIds) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-profiles-health-status');
        if (response) {
            const { profilesHealth } = response.jsonData;
            yield put(profilesHealthStatusGetSuccess(parseProfilesHealthStatus(profilesHealth)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(profilesHealthStatusGetError(applicationError));
    }
}

function* allProfileHealthStatusGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-all-profiles-health-status');
        if (response) {
            const { profilesHealth } = response.jsonData;
            yield put(allProfileHealthStatusGetSuccess(parseProfilesHealthStatus(profilesHealth)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(allProfileHealthStatusGetError(applicationError));
    }
}

function* tagProfileAsInstagramBusinessProfileRequest(action) {
    const { profileId, platformId, platformUsername } = action.payload;
    const serverRequest = createServerRequest({ profileId, platformId, platformUsername });
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/convert-instagram-profile');
        if (response) {
            const { profilesHealth } = response.jsonData;
            const parsedProfilesHealth = parseProfilesHealthStatus(profilesHealth);
            yield put(tagProfileAsInstagramBusinessProfileSuccess(profileId, parsedProfilesHealth));
            yield put(showNotification('The profile was successfully tagged as business profile'));
        } else if (serverError && _has(serverError, 'message')) {
            yield put(tagProfileAsInstagramBusinessProfileError(serverError));
            yield put(showNotification(serverError.message, 'error'));
        } else {
            throw serverError;
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(showNotification(
            'There was a problem to complete your request, please try again later or contact support@facelift-bbt.com in case the problem persists.',
            'error'
        ));
        yield put(tagProfileAsInstagramBusinessProfileError(applicationError));
    }
}

export default function* profileHealthSagas() {
    yield takeEvery([
        PROFILE_HEALTH_METRIC_DATA_GET_REQUEST,
        PROFILE_USE_CASES_SET_SUCCESS,
        removeProfileUseCaseSuccess.type
    ], profileHealthMetricDataGetRequest);
    yield takeEvery([
        PROFILE_HEALTH_STATUS_GET_REQUEST,
        PROFILE_USE_CASES_SET_SUCCESS,
        removeProfileUseCaseSuccess.type,
        TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS,
        UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS,
        UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS
    ], profileHealthStatusGetRequest);
    yield takeEvery(PROFILES_HEALTH_STATUS_GET_REQUEST, profilesHealthStatusGetRequest);
    yield takeEvery([
        authUserRemoveSuccess.type,
        FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
        FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
        YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
        TWITTER_AUTH_USER_CONNECT_SUCCESS,
        LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
        LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS,
        ACCOUNT_ENTITIES_GET_REQUEST,
        BULK_PROFILE_ADD_SUCCESS,
        ONBOARDING_BULK_PROFILE_ADD_SUCCESS
    ], allProfileHealthStatusGetRequest);
    yield takeEvery(TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST, tagProfileAsInstagramBusinessProfileRequest);
}
