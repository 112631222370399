import {
    CREATE_USER_REQUEST,
    createUserError,
    createUserSuccess,
    DELETE_USER_REQUEST,
    deleteUserError,
    deleteUserSuccess,
    RESEND_USER_INVITATION_REQUEST,
    resendUserInvitationError,
    resendUserInvitationSuccess,
    TRANSFER_AND_DELETE_REQUEST,
    transferAndDeleteUserError,
    transferAndDeleteUserSuccess,
    UPDATE_USER_REQUEST,
    updateUserError,
    updateUserSuccess,
    CREATE_ONBOARDING_USER_REQUEST,
    createOnboardingUserError,
    createOnboardingUserSuccess
} from 'src/actions/users';
import {
    call, delay, put, select, takeEvery
} from 'redux-saga/effects';
import _omit from 'lodash/omit';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { parseUserForManagement } from 'src/parsers';
import {
    modalsHideCreateUser,
    modalsHideDeleteUser,
    modalsHideEditUser,
    modalsHideTransferAndDeleteUser,
    modalShowTransferAndDeleteUser
} from 'src/actions/overlays';
import { showNotification } from 'src/actions/notifications';
import { SubmissionError } from 'redux-form';
import { reportError } from 'src/utils/reportError';
import { selectLoggedInUser, selectLoggedInUserId } from 'src/selectors/users';
import { selectLoggedInSpace } from 'src/selectors/spaces';
import { userSwitchSpaceRequest } from 'src/actions/loggedInUser';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import { getLoggedInSpaceId } from 'src/selectors/loggedInUser';

const getUserGroups = (selectedTags) => {
    const group = [];
    selectedTags.forEach((tag) => {
        group.push(tag.id);
    });
    return group;
};

const getUsedSpaces = (formData) => {
    const spaces = [];
    _forEach(formData, (formInput, key) => {
        const stringPosition = key.indexOf('space-');
        if (stringPosition > -1 && formInput[0] !== undefined) {
            const space = formInput[0];
            const { defaultSelectedTags, readOnlySpace } = space;
            if (space.spaceSelected) {
                const spaceToStore = {
                    id: key.substring(6, key.length),
                    readOnly: readOnlySpace,
                    groups: getUserGroups(defaultSelectedTags)
                };
                spaces.push(spaceToStore);
            }
        }
    });
    return spaces;
};

export const prepareUserFormValidationPayload = (serverError) => {
    const {
        firstName, lastName, email, isAdmin, password, passwordConfirmation
    } = serverError.payload;
    const submissionErrorPayload = { };

    if (firstName) submissionErrorPayload.firstName = firstName;
    if (lastName) submissionErrorPayload.lastName = lastName;
    if (email) submissionErrorPayload.email = email;
    if (isAdmin) submissionErrorPayload.isAdmin = isAdmin;
    if (password) submissionErrorPayload.password = password;
    if (passwordConfirmation) submissionErrorPayload.passwordConfirmation = passwordConfirmation;

    return submissionErrorPayload;
};

function* createUserRequest(action) {
    const { formData } = action.payload;
    const {
        email, firstName, lastName, password, passwordConfirmation, isAdmin
    } = formData;

    const spacesList = _omit(
        formData, 'id', 'email', 'firstName', 'lastName', 'password', 'passwordConfirmation',
        'isAdmin', 'usersSpaces', 'groupRestrictions'
    );
    const spaces = JSON.stringify(getUsedSpaces(spacesList));
    try {
        const params = {
            email,
            firstName,
            lastName,
            password,
            passwordConfirmation,
            spaces,
            isAdmin
        };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-user');
        if (response) {
            const { user } = response.jsonData;
            const parsedUser = parseUserForManagement(user);
            yield put(createUserSuccess(parsedUser));
            yield put(modalsHideCreateUser());
            yield put(showNotification(`Invite email to '${user.email}' was successfully sent.`));
        }
        if (serverError) {
            if (serverError.errorType === 'formValidation') {
                const submissionErrorPayload = prepareUserFormValidationPayload(serverError);
                yield put(createUserError(new SubmissionError(submissionErrorPayload)));
            } else {
                yield put(createUserError(new SubmissionError({ _error: serverError })));
            }
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(createUserError(new SubmissionError({ _error: applicationError })));
    }
}

function* createOnboardingUserRequest(action) {
    const { payload } = action;
    const { email } = payload;
    const spaceId = yield select(getLoggedInSpaceId);
    const params = {
        email,
        isAdmin: false,
        spaces: JSON.stringify([{ id: spaceId, readOnly: false }])
    };
    const serverRequest = createServerRequest(params);
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-user');
        if (response) {
            const { user } = response.jsonData;
            const parsedUser = parseUserForManagement(user);
            yield put(createOnboardingUserSuccess(parsedUser));
        }
        if (serverError) {
            if (serverError.errorType === 'formValidation') {
                const submissionErrorPayload = prepareUserFormValidationPayload(serverError);
                yield put(createOnboardingUserError(new SubmissionError(submissionErrorPayload)));
            } else {
                yield put(createOnboardingUserError(new SubmissionError({ _error: serverError })));
            }
        }
    } catch (applicationError) {
        yield put(createOnboardingUserError(new SubmissionError({ _error: applicationError })));
    }
}

function* userTriggerSpaceSwitch(modifiedUserId, url) {
    const activeUser = yield select(selectLoggedInUser);
    const activeSpace = yield select(selectLoggedInSpace);

    /* If we update selected user */
    if (activeUser.id === modifiedUserId) {
        yield put(userSwitchSpaceRequest(activeSpace.id, url));
    }
}

function* updateUserRequest(action) {
    const { formData } = action.payload;
    const {
        id, email, firstName, lastName, password, passwordConfirmation, isAdmin
    } = formData;
    const spacesList = _omit(
        formData, 'id', 'email', 'firstName', 'lastName', 'password', 'passwordConfirmation',
        'isAdmin', 'usersSpaces', 'groupRestrictions'
    );
    const spaces = JSON.stringify(getUsedSpaces(spacesList));

    try {
        const params = {
            id,
            email,
            firstName,
            lastName,
            password,
            passwordConfirmation,
            spaces,
            isAdmin
        };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-user');
        if (response) {
            const { user } = response.jsonData;
            const parsedUser = parseUserForManagement(user);
            yield put(updateUserSuccess(parsedUser));
            yield put(modalsHideEditUser(user.id));
            yield put(showNotification(`User '${parsedUser.email}' (${parsedUser.id}) updated successfully`));
            yield call(userTriggerSpaceSwitch, parsedUser.id, '/users');
        }
        if (serverError) {
            if (serverError.errorType === 'formValidation') {
                const submissionErrorPayload = prepareUserFormValidationPayload(serverError);
                yield put(updateUserError(new SubmissionError(submissionErrorPayload)));
            } else {
                throw serverError;
            }
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(updateUserError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

function* deleteUserRequest(action) {
    const { userId } = action.payload;
    try {
        const params = { userId };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/remove-user');
        if (response) {
            const { email } = response.jsonData;
            yield put(deleteUserSuccess(userId, email));
            yield put(modalsHideDeleteUser(userId));
            yield put(showNotification(`User with with email ${email} was successfully deleted.`));
        }
        if (serverError) {
            if (serverError.errorType === 'userToDeleteOwnsEntities') {
                yield put(modalShowTransferAndDeleteUser(userId, serverError.payload));
                yield put(deleteUserError(userId, serverError.message));
                yield put(modalsHideDeleteUser(userId));
            } else {
                throw serverError;
            }
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideDeleteUser(userId));
        yield put(deleteUserError(userId, applicationOrServerError.message));
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

function* transferAndDeleteUserRequest(action) {
    const { formData } = action.payload;
    const { transferFromUserId } = formData;

    try {
        const regex = /space-(\d+)/;
        const spaceUsers = {};
        Object.keys(formData).forEach((key) => {
            const match = regex.exec(key);
            if (_has(match, '1')) {
                Object.assign(spaceUsers, { [match[1]]: formData[key] });
            }
        });
        const transferServerRequest = createServerRequest({
            transferFromUserId,
            spaceUsers: JSON.stringify(spaceUsers)
        });
        const {
            response: transferResponse,
            serverError: transferServer
        } = yield handleAuthorizedServerRequest(transferServerRequest, '/client-index/transfer-user');
        if (transferResponse) {
            const deleteServerRequest = createServerRequest({
                userId: transferFromUserId
            });
            const {
                response: deleteResponse,
                serverError: deleteServerError
            } = yield handleAuthorizedServerRequest(deleteServerRequest, '/client-index/remove-user');

            if (deleteResponse) {
                const { email } = deleteResponse.jsonData;
                const loggedInUserId = yield select(selectLoggedInUserId);
                yield put(transferAndDeleteUserSuccess(transferFromUserId, email));
                yield put(modalsHideTransferAndDeleteUser(transferFromUserId));
                yield put(showNotification(`User with with email ${email} was successfully transferred and deleted.`));
                const { jsonData } = transferResponse;
                if (_has(jsonData, loggedInUserId)) {
                    yield put(showNotification('Your account was affected by transfers. The tool will reload now.'));
                    yield delay(2000);
                    // eslint-disable-next-line no-undef
                    window.location.reload();
                }
            }
            if (deleteServerError) {
                throw deleteServerError;
            }
        }
        if (transferServer) {
            throw transferServer;
        }
    } catch (applicationOrServerError) {
        yield put(transferAndDeleteUserError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

function* resendUserInvitation(action) {
    const { userId } = action.payload;
    try {
        const params = { userId };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/resend-user-invitation');
        if (response) {
            const { user } = response.jsonData;
            const parsedUser = parseUserForManagement(user);
            yield put(resendUserInvitationSuccess(userId, parsedUser));
            yield put(showNotification(`A new Invitation has been send to user ${parsedUser.email}.`));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideDeleteUser(userId));
        yield put(resendUserInvitationError(userId, applicationOrServerError));
        yield put(showNotification('Something went wrong while resending the invitation', 'error'));
    }
}

export default function* usersSaga() {
    yield takeEvery(CREATE_USER_REQUEST, createUserRequest);
    yield takeEvery(CREATE_ONBOARDING_USER_REQUEST, createOnboardingUserRequest);
    yield takeEvery(UPDATE_USER_REQUEST, updateUserRequest);
    yield takeEvery(DELETE_USER_REQUEST, deleteUserRequest);
    yield takeEvery(TRANSFER_AND_DELETE_REQUEST, transferAndDeleteUserRequest);
    yield takeEvery(RESEND_USER_INVITATION_REQUEST, resendUserInvitation);
}
