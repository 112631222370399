import { put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST,
    profileGetAudienceDataOverviewSuccess,
    profileGetAudienceDataOverviewError
} from 'src/actions/profileAudienceData';
import _parseInt from 'lodash/parseInt';
import { parseProfileAudienceData } from 'src/parsers';

function* profileGetAudienceDataRequest(action) {
    const { payload } = action;
    const { profileId } = payload;
    try {
        const serverRequest = createServerRequest({ profileId: _parseInt(profileId) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-profile-audience-data-overview');
        if (response) {
            const { profileAudienceData, warning } = response.jsonData;
            if (warning) {
                yield put(profileGetAudienceDataOverviewSuccess(profileId, null, warning));
            } else {
                const parsedProfileAudienceData = parseProfileAudienceData(profileAudienceData);
                yield put(profileGetAudienceDataOverviewSuccess(parsedProfileAudienceData.profileId, parsedProfileAudienceData));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (ApplicationError) {
        yield put(profileGetAudienceDataOverviewError(profileId, ApplicationError));
    }
}

export default function* profileAudienceDataOverviewSagas() {
    yield takeEvery(PROFILE_GET_AUDIENCE_DATA_OVERVIEW_REQUEST, profileGetAudienceDataRequest);
}
