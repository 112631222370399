import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { getPackageStatus } from 'src/selectors/adminSettings';
import { modalsShowGeneralInfo } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { selectIsAdmin } from 'src/selectors/loggedInUser';
import { planRoute, billingRoute } from 'src/routePaths';
import memoizeOne from 'memoize-one';

const allowedRoutes = [planRoute, billingRoute];
const isBlocked = ({ blocked, status }) => blocked || status === 'expired' || status === 'free';
const isViewBlocked = (location) => allowedRoutes.indexOf(location.pathname) < 0;

const infoLookup = {
    expired: {
        title: 'Account expired',
        message: (
            <div>
                Your account has expired.
                To continue using our tool please renew your subscription or contact our support team at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a>.
            </div>
        )
    },
    expiredNonAdmin: {
        title: 'Account expired',
        message: (
            <div>
                Your account has expired.
                Please reach out to the administrator of your account to perform changes of this kind.
                In case of further questions on how to set up rights management within your account,
                feel free to also reach out to our support team at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a>.
            </div>
        )
    },
    blocked: {
        title: 'Account blocked',
        message: (
            <div>
                Your account has been blocked.
                Learn more about blocked accounts in our <a href="https://support.quintly.com/en/articles/4412198-why-is-my-account-blocked" target="_new">knowledge base</a>.
                Please contact our support team at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a> to resolve this issue.
            </div>
        )
    },
    free: {
        title: 'The free version is no longer supported',
        message: (
            <div>
                As of September 30, 2017, we have removed the free version from our plans.
                To continue using quintly, please upgrade your plan or contact our support team at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a>
            </div>
        )
    }
};

const withBlockedOrExpiredCheck = (WrappedComponent) => {
    const WithBlockedOrExpiredCheck = (props) => {
        const {
            viewBlocked, packageStatus, isAdmin, redirectToPlanPage, routeReplaceAction
        } = props;

        const redirectAndInform = () => {
            const { modalsShowGeneralInfoAction } = props;
            const { status, blocked } = packageStatus;
            let info = {};
            if (blocked) {
                info = infoLookup.blocked;
            } else if (status === 'free') {
                info = infoLookup.free;
            } else if (isAdmin) {
                info = infoLookup.expired;
            } else {
                info = infoLookup.expiredNonAdmin;
            }
            if (redirectToPlanPage) {
                routeReplaceAction({ pathname: planRoute });
            }
            modalsShowGeneralInfoAction(info);
        };

        useEffect(() => {
            if (viewBlocked) {
                redirectAndInform();
            }
        }, [viewBlocked]);

        const cleanedProps = _omit(props, ['redirectToPlanPage']);
        if (viewBlocked === true) {
            return <WrappedComponent {...cleanedProps} content={<div>Blocked</div>} />;
        }
        return <WrappedComponent {...cleanedProps} />;
    };

    WithBlockedOrExpiredCheck.propTypes = {
        packageStatus: customPropTypes.packageStatus.isRequired,
        routeReplaceAction: PropTypes.func.isRequired,
        location: customPropTypes.location.isRequired,
        modalsShowGeneralInfoAction: PropTypes.func.isRequired,
        isAdmin: PropTypes.bool.isRequired,
        redirectToPlanPage: PropTypes.bool,
        viewBlocked: PropTypes.bool.isRequired
    };

    WithBlockedOrExpiredCheck.defaultProps = {
        redirectToPlanPage: true
    };

    const makeMapStateToProps = () => {
        const memoizedIsBlocked = memoizeOne(isBlocked);
        const memoizedIsViewBlocked = memoizeOne(isViewBlocked);
        return (state, ownProps) => {
            const { location } = ownProps;
            const packageStatus = getPackageStatus(state);
            return {
                viewBlocked: memoizedIsBlocked(packageStatus) ? memoizedIsViewBlocked(location) : false,
                packageStatus: getPackageStatus(state),
                isAdmin: selectIsAdmin(state)
            };
        };
    };

    return connect(makeMapStateToProps, {
        routeReplaceAction: routeActions.replace,
        modalsShowGeneralInfoAction: modalsShowGeneralInfo
    })(WithBlockedOrExpiredCheck);
};

export default withBlockedOrExpiredCheck;
