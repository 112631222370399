import { put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    PROFILE_EVENTS_GET_REQUEST,
    profileEventsGetError,
    profileEventsGetSuccess
} from 'src/actions/profileEvents';
import _parseInt from 'lodash/parseInt';
import { parseProfileEvents } from 'src/parsers';
import { PROFILE_USE_CASES_SET_SUCCESS, removeProfileUseCaseSuccess } from 'src/actions/profileUseCases';
import { TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS } from 'src/actions/profileHealth';

function* profileEventsGetRequest(action) {
    const { payload } = action;
    const { profileId } = payload;

    try {
        const serverRequest = createServerRequest({ profileId: _parseInt(profileId) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-profile-events');

        if (response) {
            const { events } = response.jsonData;
            const parsedProfileEvents = parseProfileEvents(events);
            yield put(profileEventsGetSuccess(profileId, parsedProfileEvents));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(profileEventsGetError(profileId, applicationError));
    }
}

export default function* profileEventSagas() {
    yield takeEvery([
        PROFILE_EVENTS_GET_REQUEST,
        PROFILE_USE_CASES_SET_SUCCESS,
        removeProfileUseCaseSuccess.type,
        TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS
    ], profileEventsGetRequest);
}
