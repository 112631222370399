import { LIMITS_GET_SUCCESS } from 'src/actions/limits';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export function spaceLimits(state = { limits: {}, usage: {} }, action) {
    const { payload, type } = action;
    switch (type) {
        case LIMITS_GET_SUCCESS:
        case USER_LOGGED_IN: {
            const { spaceLimits: spaceLimitsFromPayload } = payload;
            return Object.assign({}, state, spaceLimitsFromPayload);
        }
        default:
            return state;
    }
}
