import AuthenticatorContext from 'src/components/context/AuthenticatorContext';
import AppError from 'src/components/errors/AppError';
import ConfirmAccountError from 'src/components/errors/ConfirmAccountError';
import React from 'react';
import PropTypes from 'prop-types';

const Auth0LoginErrorHandler = ({
    error, logout
}) => {
    if (error.message === 'confirm_account') {
        return <ConfirmAccountError error={error} logout={logout} />;
    }
    return (
        <AuthenticatorContext.Provider value={{ logout }}>
            <AppError error={error} />;
        </AuthenticatorContext.Provider>
    );
};

Auth0LoginErrorHandler.propTypes = {
    error: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

export default Auth0LoginErrorHandler;
