import copy from 'copy-to-clipboard';
import _parseInt from 'lodash/parseInt';
import { SubmissionError } from 'redux-form';
import {
    all, call, put, select, takeEvery
} from 'redux-saga/effects';
import { showNotification, showProfileBatchAddingSuccessOrWarning } from 'src/actions/notifications';
import { setFailedToBeAddedProfileUrls } from 'src/actions/onboarding';
import {
    modalsHideForceDeleteProfileModal,
    modalsHideGroupSelectorProfile,
    modalsHideProfileDelete,
    modalsHideProfileDetails,
    modalsHideProfileSearch,
    modalShowProfilesAddedFailedStatus,
    modalsShowForceDeleteProfileModal
} from 'src/actions/overlays';
import {
    BULK_PROFILE_ADD_REQUEST,
    bulkProfileAddError,
    bulkProfileAddSuccess,
    cleanupProfileAddToOrRemoveFromGroup,
    EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_REQUEST,
    externalNetworkAuthenticationLinkGetError,
    externalNetworkAuthenticationLinkGetSuccess,
    ONBOARDING_BULK_PROFILE_ADD_REQUEST,
    onboardingBulkProfileAddError,
    onboardingBulkProfileAddSuccess,
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST,
    PROFILE_CUSTOMIZE_REQUEST,
    PROFILE_DELETE_REQUEST,
    PROFILE_FORCE_DELETE_REQUEST,
    profileAddError,
    profileAddRequest,
    profileAddStateCleanup,
    profileAddSuccess,
    profileAddToOrRemoveFromGroupError,
    profileAddToOrRemoveFromGroupSuccess,
    profileCustomizeError,
    profileCustomizeSuccess,
    profileDeleteError,
    profileDeleteSuccess,
    profileForceDeleteError,
    profileForceDeleteSuccess,
    UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST,
    UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST,
    updateInstagramPlatformUsernameError,
    updateInstagramPlatformUsernameSuccess,
    updateTiktokPlatformUsernameError,
    updateTiktokPlatformUsernameSuccess
} from 'src/actions/profiles';
import {
    convertArrayElementsToString,
    getForceOperationalEntityIds,
    getForceOperationalEntityInfos,
    parseProfile,
    parseProfiles
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    accountEntitiesGetRequest,
    collectBatchAccountDeleteResponses,
    createBatchProfileDeleteRequests,
    performSequentialBulkAccountAddRequests
} from 'src/sagas/accounts';
import {
    gatherBatchedResults,
    getAddedAndRemovedIdsFromSelectedOptions,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import {
    makeSelectGroupsIdsByProfileIdsWithIndeterminatedGroups,
    makeSelectProfilesByIds
} from 'src/selectors/profiles';
import { makeSelectSearchedProfilesByIds } from 'src/selectors/profileSearch';
import { reportError } from 'src/utils/reportError';

const selectProfilesByIds = makeSelectProfilesByIds();
const selectSearchedProfilesByIds = makeSelectSearchedProfilesByIds();
const selectGroupsIdsByProfileIdsWithIndeterminatedGroups = makeSelectGroupsIdsByProfileIdsWithIndeterminatedGroups();

function* addRequest(id) {
    yield put(profileAddRequest(id));
}

function* successRequest(id) {
    yield put(profileAddSuccess(id));
}

function* errorRequest(id) {
    yield put(profileAddError(id, 'Profile adding failed'));
}

function* cleanProfileAddState(searchedProfile) {
    yield put(profileAddStateCleanup(searchedProfile.id));
}

function* cleanProfileAddStates(searchedProfiles) {
    const stateCleanupRequests = searchedProfiles.map((searchedProfile) => call(cleanProfileAddState, searchedProfile));
    yield all(stateCleanupRequests);
}

function* bulkProfileAddRequest(action) {
    const { payload } = action;
    try {
        const { checkedProfileIds } = payload;
        const searchedProfiles = yield select(selectSearchedProfilesByIds, checkedProfileIds);
        const { allAddedAccounts, failedSearchedAccountLinks: failedSearchedProfileLinks } = yield call(performSequentialBulkAccountAddRequests, searchedProfiles, '/client-index/add-profiles', addRequest, successRequest, errorRequest);
        const allAddedProfiles = parseProfiles(allAddedAccounts);
        yield call(accountEntitiesGetRequest);
        yield put(bulkProfileAddSuccess(allAddedProfiles));
        yield put(modalsHideProfileSearch());
        yield put(modalShowProfilesAddedFailedStatus(Object.keys(allAddedProfiles), failedSearchedProfileLinks));
        yield call(cleanProfileAddStates, searchedProfiles);
    } catch (applicationError) {
        yield put(bulkProfileAddError(applicationError));
    }
}

function* onboardingBulkProfileAddRequest(action) {
    const { payload } = action;
    try {
        const { checkedProfileIds } = payload;
        const searchedProfiles = yield select(selectSearchedProfilesByIds, checkedProfileIds);
        const { allAddedAccounts, failedSearchedAccountLinks: failedSearchedProfileLinks } = yield call(performSequentialBulkAccountAddRequests, searchedProfiles, '/client-index/add-profiles', addRequest, successRequest, errorRequest);
        const allAddedProfiles = parseProfiles(allAddedAccounts);
        yield call(accountEntitiesGetRequest);
        yield put(onboardingBulkProfileAddSuccess(allAddedProfiles));
        if (failedSearchedProfileLinks.length > 0) {
            yield put(setFailedToBeAddedProfileUrls(failedSearchedProfileLinks));
        }
        yield call(cleanProfileAddStates, searchedProfiles);
    } catch (applicationError) {
        yield put(onboardingBulkProfileAddError(applicationError));
    }
}

function* getForceOperationalProfileInfos(forceOperationalProfilesInfo) {
    const forceOperationalProfileIds = getForceOperationalEntityIds(forceOperationalProfilesInfo);
    const forceOperationalProfiles = yield select(selectProfilesByIds, forceOperationalProfileIds);
    const forceOperationalProfileInfos = getForceOperationalEntityInfos(forceOperationalProfiles, forceOperationalProfilesInfo);
    return forceOperationalProfileInfos;
}

function* profileDeleteRequest(action) {
    const { payload } = action;
    const { profileIds } = payload;
    try {
        const profilesDeleteRequests = createBatchProfileDeleteRequests(profileIds);
        const responses = yield all(profilesDeleteRequests);
        const { allRemovedProfileIds, allNotRemovedProfileIds, allForceRemovableProfilesInfo } = collectBatchAccountDeleteResponses(responses);
        const profileCount = allRemovedProfileIds.length;
        yield put(modalsHideProfileDelete());
        yield put(modalsHideProfileDetails(profileIds[0]));
        yield put(profileDeleteSuccess(allRemovedProfileIds.map((id) => id.toString())));
        if (profileCount > 0) {
            const profileString = profileCount === 1 ? 'profile was' : 'profiles were';
            yield put(showNotification(`${profileCount} ${profileString} successfully deleted from your account.`));
        }
        if (allForceRemovableProfilesInfo.length > 0 || allNotRemovedProfileIds.length > 0) {
            const forceOperationalProfileInfos = yield getForceOperationalProfileInfos(allForceRemovableProfilesInfo);
            yield put(modalsShowForceDeleteProfileModal(forceOperationalProfileInfos, convertArrayElementsToString(allNotRemovedProfileIds)));
        }
        yield call(accountEntitiesGetRequest);
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideProfileDelete());
        yield put(profileDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your profile failed.', 'error'));
    }
}

function* profileForceDeleteRequest(action) {
    const { payload } = action;
    const { profileIds } = payload;
    const requestParams = { accountIds: JSON.stringify(profileIds.map((id) => _parseInt(id))) };
    try {
        const serverRequest = createServerRequest(requestParams);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/force-delete-profile');
        if (response) {
            const { removedProfileIds } = response.jsonData;
            const parsedProfileIds = convertArrayElementsToString(removedProfileIds);
            yield put(modalsHideForceDeleteProfileModal());
            yield put(profileForceDeleteSuccess(parsedProfileIds));
            const profileCount = parsedProfileIds.length;
            if (profileCount > 0) {
                const profileString = profileCount === 1 ? 'profile was' : 'profiles were';
                yield put(showNotification(`${profileCount} ${profileString} successfully deleted from your account.`));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideForceDeleteProfileModal());
        yield put(profileForceDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your profile failed.', 'error'));
    }
}

function* profileAddToOrRemoveFromGroupServerRequest(profileId, addToGroups, removeFromGroups) {
    const serverRequest = createServerRequest({
        profileId,
        addToGroups: JSON.stringify(addToGroups),
        removeFromGroups: JSON.stringify(removeFromGroups)
    });
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-remove-pages-to-group');
    return { response, serverError, profileId };
}

function* getAddedAndRemovedGroupIds(profileIds, selectedGroupOptions) {
    const { selectedGroupIds, indeterminateGroupIds } = yield select(selectGroupsIdsByProfileIdsWithIndeterminatedGroups, profileIds);
    const { addedIds, removedIds } = getAddedAndRemovedIdsFromSelectedOptions(selectedGroupIds, indeterminateGroupIds, selectedGroupOptions);
    return {
        addToGroups: addedIds,
        removeFromGroups: removedIds
    };
}

function* profileAddToOrRemoveFromGroupRequest(action) {
    const { payload } = action;
    const { selectedGroupOptions, profileIds } = payload;
    const { addToGroups, removeFromGroups } = yield call(getAddedAndRemovedGroupIds, profileIds, selectedGroupOptions);
    try {
        const requests = profileIds.map((profileId) => call(profileAddToOrRemoveFromGroupServerRequest, profileId, addToGroups, removeFromGroups));

        const collectedResponses = yield all(requests);
        const profileAddOrRemoveResults = gatherBatchedResults(collectedResponses);

        yield put(modalsHideGroupSelectorProfile());
        yield put(profileAddToOrRemoveFromGroupSuccess(profileAddOrRemoveResults.success));
        yield put(showProfileBatchAddingSuccessOrWarning(profileAddOrRemoveResults));
        yield put(cleanupProfileAddToOrRemoveFromGroup());
    } catch (applicationError) {
        reportError(applicationError);
        yield put(profileAddToOrRemoveFromGroupError(applicationError));
        yield put(showNotification('Unable to add or remove profiles from your group.', 'error'));
    }
}

function* profileCustomizeRequest(action) {
    const { payload } = action;
    const {
        profileId, appendix, color, favorite
    } = payload;
    try {
        const serverRequest = createServerRequest({
            pageId: profileId, color, appendix, favorite
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/customize-profile');
        if (response) {
            const parsedProfile = parseProfile(response.jsonData);
            yield put(profileCustomizeSuccess(parsedProfile));
            yield put(showNotification('Your profile settings were successfully saved.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(profileCustomizeError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

export function* updateInstagramPlatformUsernameRequest(action) {
    const {
        profileId,
        platformUsername
    } = action.payload;

    try {
        const serverRequest = createServerRequest({ profileId, platformUsername });
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-instagram-profile-username');
        if (response) {
            const { profile } = response.jsonData;
            const parsedProfile = parseProfile(profile);
            yield put(updateInstagramPlatformUsernameSuccess(parsedProfile.id, parsedProfile));
            yield put(showNotification('The selected profile was successfully updated'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(updateInstagramPlatformUsernameError(profileId, applicationOrServerError));
        yield put(showNotification('The selected profile could not be updated', 'error'));
    }
}

export function* updateTiktokPlatformUsernameRequest(action) {
    const {
        profileId,
        platformUsername
    } = action.payload;

    try {
        const serverRequest = createServerRequest({ profileId, platformUsername });
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-tiktok-profile-username');
        if (response) {
            const { profile } = response.jsonData;
            const parsedProfile = parseProfile(profile);
            yield put(updateTiktokPlatformUsernameSuccess(parsedProfile.id, parsedProfile));
            yield put(showNotification('The selected profile was successfully updated.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(updateTiktokPlatformUsernameError(profileId, applicationOrServerError));
        yield put(showNotification('The selected profile could not be updated.', 'error'));
    }
}

function* externalNetworkAuthenticationLinkGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-external-social-network-authentication-link');
        if (response) {
            const { link } = response.jsonData;
            yield call(copy, link);
            yield put(externalNetworkAuthenticationLinkGetSuccess());
            yield put(showNotification('Link copied to clipboard.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification('Link could not be generated, please try again.', 'error'));
        yield put(externalNetworkAuthenticationLinkGetError(applicationError.message));
    }
}

export default function* profilesSagas() {
    yield takeEvery(BULK_PROFILE_ADD_REQUEST, bulkProfileAddRequest);
    yield takeEvery(ONBOARDING_BULK_PROFILE_ADD_REQUEST, onboardingBulkProfileAddRequest);
    yield takeEvery(PROFILE_DELETE_REQUEST, profileDeleteRequest);
    yield takeEvery(PROFILE_FORCE_DELETE_REQUEST, profileForceDeleteRequest);
    yield takeEvery(PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST, profileAddToOrRemoveFromGroupRequest);
    yield takeEvery(PROFILE_CUSTOMIZE_REQUEST, profileCustomizeRequest);
    yield takeEvery(UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST, updateInstagramPlatformUsernameRequest);
    yield takeEvery(UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST, updateTiktokPlatformUsernameRequest);
    yield takeEvery(EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_REQUEST, externalNetworkAuthenticationLinkGetRequest);
}
