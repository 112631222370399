import {
    PROFILE_HEALTH_METRIC_DATA_GET_ERROR,
    PROFILE_HEALTH_METRIC_DATA_GET_REQUEST,
    PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS,
    PROFILE_HEALTH_STATUS_GET_SUCCESS,
    PROFILES_HEALTH_STATUS_GET_SUCCESS,
    ALL_PROFILE_HEALTH_STATUS_GET_SUCCESS,
    TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS,
    TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST,
    TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_ERROR
} from 'src/actions/profileHealth';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { combineReducers } from 'redux';
import {
    createAsyncStatesReducerForAction,
    createAsyncStatesReducerForActionsWithErrorAndSuccessStates
} from 'src/reducers/utils';
import { PROFILE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_SUCCESS } from 'src/actions/profiles';
import _omit from 'lodash/omit';

const statusById = (state = { }, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ALL_PROFILE_HEALTH_STATUS_GET_SUCCESS:
        case PROFILES_HEALTH_STATUS_GET_SUCCESS: {
            const { profilesHealthStatus } = payload;
            return Object.assign({}, state, profilesHealthStatus);
        }
        case PROFILE_HEALTH_STATUS_GET_SUCCESS: {
            const { profileId, status } = payload;
            return Object.assign({}, state, { [profileId]: status });
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return _omit(state, profileIds);
        }
        case TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS: {
            const { profilesHealth } = payload;
            return Object.assign({}, state, profilesHealth);
        }
        default: {
            return state;
        }
    }
};

const healthMetricDataById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS: {
            const { profileId, healthMetricData } = payload;
            return Object.assign({}, state, { [profileId]: healthMetricData });
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return _omit(state, profileIds);
        }
        default: {
            return state;
        }
    }
};

const asyncStates = combineReducers({
    healthData: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.profileId,
        [PROFILE_HEALTH_METRIC_DATA_GET_REQUEST],
        [PROFILE_HEALTH_METRIC_DATA_GET_SUCCESS],
        [PROFILE_HEALTH_METRIC_DATA_GET_ERROR]
    ),
    convertInstagramProfile: createAsyncStatesReducerForAction(
        () => 'isConverting',
        [TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_REQUEST],
        [TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_SUCCESS, TAG_PROFILE_AS_INSTAGRAM_BUSINESS_PROFILE_ERROR]
    )
});

export const profileHealth = combineReducers({
    statusById,
    healthMetricDataById,
    asyncStates
});
