import { ACCOUNT_OPTIONS_SAVE_SUCCESS, USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { takeEvery, call } from 'redux-saga/effects';

import _get from 'lodash/get';
import { updateWeekDefinition } from 'src/utils/localization';

function* userLoggedIn(action) {
    const { payload } = action;
    const weekDefinition = _get(payload, 'loggedInUser.accountOptions.weekDefinition', false);
    yield call(updateWeekDefinition, weekDefinition);
}

function* accountOptionsSaved(action) {
    const { payload } = action;
    const weekDefinition = _get(payload, 'accountOptions.weekDefinition', false);
    yield call(updateWeekDefinition, weekDefinition);
}

function* sharedDashboardBootstrap(action) {
    const { payload } = action;
    const weekDefinition = _get(payload, 'accountOptions.weekDefinition', false);
    yield call(updateWeekDefinition, weekDefinition);
}

export default function* localizationSagas() {
    yield takeEvery(USER_LOGGED_IN, userLoggedIn);
    yield takeEvery(ACCOUNT_OPTIONS_SAVE_SUCCESS, accountOptionsSaved);
    yield takeEvery(SHARED_DASHBOARD_BOOTSTRAP_SUCCESS, sharedDashboardBootstrap);
}
