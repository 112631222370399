import { getScopesByUseCases, handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    all, call, put, takeEvery, select
} from 'redux-saga/effects';
import { parseBatchedSearchedProfiles, parseSearchedProfiles } from 'src/parsers';
import {
    PROFILE_SEARCH_BY_KEYWORD_REQUEST,
    PROFILE_SEARCH_BY_URLS_REQUEST,
    PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST,
    profileSearchByAuthProfilesError,
    profileSearchByAuthProfilesSuccess,
    profileSearchSuccess,
    profileSearchByUrlsError,
    profileSearchByUrlsSuccess,
    profileSearchByKeywordError,
    profileSearchByKeywordSuccess,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST,
    socialNetworkProfileShowListError,
    socialNetworkProfileShowListSuccess,
    SOCIAL_NETWORK_SEARCH_GRAPH_FORCE_CONNECT_REQUEST,
    SOCIAL_NETWORK_SEARCH_SNAPCHAT_FORCE_CONNECT_REQUEST,
    SOCIAL_NETWORK_SEARCH_LINKED_IN_FORCE_CONNECT_REQUEST,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST,
    socialNetworkProfileShowListByStateError, socialNetworkProfileShowListByStateSuccess
} from 'src/actions/profileSearch';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { reportError } from 'src/utils/reportError';
import { extractLinksFromInput, listName } from 'src/utils/profileSearch';
import { checkListValues } from 'src/actions/lists';
import { makeSelectAlreadyAddedProfilesByTempIds, makeSelectCurrentProfileLimitCountAndType } from 'src/selectors/profileSearch';
import _difference from 'lodash/difference';
import { chunkArray } from 'src/utils/array';
import _uniq from 'lodash/uniq';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import {
    modalsShowFacebookAuthUserInAnotherSocialNetworkSearchWarning,
    modalsLinkedInAuthUserInAnotherSpaceSocialNetworkSearchWarning,
    modalsShowSnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarning
} from 'src/actions/overlays';
import {
    graphAuthUserAuthenticationRequest,
    linkedInAuthUserAuthenticationRequest,
    snapchatAuthUserAuthenticationRequest,
    youtubeAuthUserAuthenticationRequest,
    twitterAuthUserAuthenticationRequest,
    tiktokAuthUserAuthenticationRequest,
} from 'src/sagas/authUsers';
import {
    generateRandomTwitterRequestId,
    getThreadsAuthenticationCallbackUrl
} from 'src/utils/authUsers';

const selectCheckedValues = makeSelectCheckedValues();
const selectNumberOfProfilesToBeAdded = makeSelectCurrentProfileLimitCountAndType();
const selectAlreadyAddedProfilesByTempIds = makeSelectAlreadyAddedProfilesByTempIds();

function* profileSearchRequest(action) {
    const { profileSearchInput } = action.payload;
    try {
        const serverRequest = createServerRequest({ query: profileSearchInput });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-profiles');
        if (response) {
            const {
                profiles,
                resultType,
                hints,
                errors
            } = response.jsonData;
            const parsedProfiles = parseSearchedProfiles(profiles);
            yield put(profileSearchByKeywordSuccess(profileSearchInput, parsedProfiles, resultType, hints, errors));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(profileSearchByKeywordError(profileSearchInput, applicationOrServerError));
    }
}

function* profileBatchSearchRequest(urls) {
    let failedUrls = [];
    let succeededLinksByTempId = {};
    let hints = [];
    try {
        const serverRequest = createServerRequest({ urls: JSON.stringify(urls) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-profile-by-urls');
        if (response) {
            const { profiles, errors, hints: currentHints } = response.jsonData;
            const { parsedProfiles, linksByTempIds } = parseBatchedSearchedProfiles(profiles);
            yield put(profileSearchSuccess(parsedProfiles));
            succeededLinksByTempId = linksByTempIds;
            failedUrls = Object.keys(errors);
            hints = currentHints;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        failedUrls = urls;
    }
    return {
        succeededLinksByTempId,
        failedUrls,
        hints
    };
}

const collectSucceededAndFailedSearchResponses = (responses) => {
    const succeededLinksByTempId = {};
    let failedLinks = [];
    let allHints = [];
    responses.forEach((response) => {
        const { succeededLinksByTempId: currentSucceededLinksByTempId, failedUrls, hints } = response;
        failedLinks = _uniq([...failedLinks, ...failedUrls]);
        allHints = _uniq([...allHints, ...hints]);
        Object.assign(succeededLinksByTempId, currentSucceededLinksByTempId);
    });
    return {
        succeededLinksByTempId,
        failedLinks,
        allHints
    };
};

const createBatchProfilesSearchRequests = (urls) => {
    const chunks = chunkArray(urls, 10);
    return chunks.map((ids) => call(profileBatchSearchRequest, ids));
};

function* profileBulkSearchRequest(action) {
    const { profileSearchInput } = action.payload;
    const { validLinks, inValidLinks } = extractLinksFromInput(profileSearchInput);

    try {
        const searchRequests = createBatchProfilesSearchRequests(validLinks);
        const responses = yield all(searchRequests);
        const { succeededLinksByTempId, failedLinks, allHints } = collectSucceededAndFailedSearchResponses(responses);
        const succeededTempIds = Object.keys(succeededLinksByTempId);
        const existingAddedTempIds = yield select(selectAlreadyAddedProfilesByTempIds, succeededTempIds);
        const existingSelectedTempIds = yield select(selectCheckedValues, listName);
        const excludedTempIds = existingAddedTempIds.concat(existingSelectedTempIds);
        const newProfileTempIds = _difference(succeededTempIds, excludedTempIds);
        const numberOfProfilesTobeAdded = yield select(selectNumberOfProfilesToBeAdded);
        const tempIdsToBeAdded = newProfileTempIds.slice(0, numberOfProfilesTobeAdded.count);
        const outOfProfileLimitTempIds = newProfileTempIds.slice(numberOfProfilesTobeAdded.count, newProfileTempIds.length);
        const outOfProfileLimitLinks = outOfProfileLimitTempIds.map((id) => succeededLinksByTempId[id]);

        // add the succeeded profile ids to the profile selection
        yield put(checkListValues(listName, tempIdsToBeAdded));
        const allFailedLinks = [...failedLinks, ...inValidLinks];
        yield put(profileSearchByUrlsSuccess(allFailedLinks, outOfProfileLimitLinks, allHints));
    } catch (applicationError) {
        reportError(applicationError);
        yield put(profileSearchByUrlsError(applicationError));
    }
}

function* profileSearchByAuthProfiles() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-profiles-by-auth-profiles');
        if (response) {
            const { profiles } = response.jsonData;
            const parsedProfiles = parseSearchedProfiles(profiles);
            yield put(profileSearchByAuthProfilesSuccess(parsedProfiles));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(profileSearchByAuthProfilesError(applicationOrServerError));
    }
}

function* performSocialNetworkProfileShowListSuccessOrThrowError(response = null) {
    let parsedProfiles = {};
    if (response !== null) {
        const { profiles, errors } = response.jsonData;
        if (errors.length > 0) {
            throw new Error(errors[0]);
        }
        parsedProfiles = parseSearchedProfiles(profiles);
    }
    yield put(socialNetworkProfileShowListSuccess(parsedProfiles));
}

function* facebookPagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const serverRequest = createServerRequest({ shortLiveAccessToken: accessToken });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-graph-profiles');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(modalsShowFacebookAuthUserInAnotherSocialNetworkSearchWarning(accessToken, 'profiles'));
                } else {
                    yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* socialNetworkSearchGraphForceConnectRequest(action) {
    const { payload } = action;
    const { accessToken } = payload;
    try {
        const serverRequest = createServerRequest({ shortLiveAccessToken: accessToken, isAllowedInNewSpace: true });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-graph-profiles');
        if (response) {
            yield call(performSocialNetworkProfileShowListSuccessOrThrowError, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* linkedInPagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(linkedInAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const authorizedRequest = createServerRequest({ code: response.code });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-index/find-linked-in-profiles');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshToken,
                        validUntil,
                        refreshTokenValidUntil,
                        platformUserId,
                        platformUserName
                    } = authorizedResponse.jsonData;
                    yield put(modalsLinkedInAuthUserInAnotherSpaceSocialNetworkSearchWarning(accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName));
                } else {
                    yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* linkedInPagesShowListForceConnectRequest(action) {
    const { payload } = action;
    const {
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName
    } = payload;
    try {
        const serverRequest = createServerRequest({
            accessToken,
            refreshToken,
            validUntil,
            refreshTokenValidUntil,
            platformUserId,
            platformUserName
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-linked-in-profiles-force');
        if (response) {
            yield call(performSocialNetworkProfileShowListSuccessOrThrowError, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* twitterPagesShowListRequest() {
    const requestId = generateRandomTwitterRequestId();
    try {
        const { response, serverError } = yield call(twitterAuthUserAuthenticationRequest, requestId);
        if (response) {
            const { oauthToken, oauthVerifier } = response;
            const authorizedRequest = createServerRequest({
                oauthToken,
                oauthVerifier,
                requestId
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-index/find-twitter-profiles');
            if (authorizedResponse) {
                yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* youtubePagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(youtubeAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const authorizedRequest = createServerRequest({ code: response.code });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-index/find-youtube-profiles');
            if (authorizedResponse) {
                yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* snapchatPagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(snapchatAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const authorizedRequest = createServerRequest({ code: response.code });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-index/find-snapchat-profiles');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshAccessToken,
                        expiresIn,
                        platformUserId,
                        displayName
                    } = authorizedResponse.jsonData;
                    yield put(modalsShowSnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarning(
                        accessToken, refreshAccessToken, expiresIn, platformUserId, displayName
                    ));
                } else {
                    yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* snapchatPagesShowListForceConnectRequest(action) {
    const { payload } = action;
    const {
        accessToken,
        expiresIn,
        refreshAccessToken,
        platformUserId,
        displayName
    } = payload;
    try {
        const serverRequest = createServerRequest({
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-snapchat-profiles-force');
        if (response) {
            yield call(performSocialNetworkProfileShowListSuccessOrThrowError, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* tiktokPagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(tiktokAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const serverRequest = createServerRequest({ code: response.code });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-tiktok-profiles');
            if (authorizedResponse) {
                yield call(performSocialNetworkProfileShowListSuccessOrThrowError, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* threadsRedirectOauthRequest(useCaseIds) {
    try {
        const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'threads');
        const url = getThreadsAuthenticationCallbackUrl(finalScopes);
        const serverRequest = createServerRequest({
            url,
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/redirect-threads-oauth-profile-add');
        if (response) {
            const { stateFulRedirect } = response.jsonData;
            window.location.href = stateFulRedirect;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* socialNetworkPagesShowListRequest(action) {
    const { network, useCaseIds } = action.payload;
    if (network === 'facebook') {
        yield call(facebookPagesShowListRequest, useCaseIds);
    }
    if (network === 'youtube') {
        yield call(youtubePagesShowListRequest, useCaseIds);
    }
    if (network === 'twitter') {
        yield call(twitterPagesShowListRequest, useCaseIds);
    }
    if (network === 'linkedIn') {
        yield call(linkedInPagesShowListRequest, useCaseIds);
    }
    if (network === 'snapchatShow') {
        yield call(snapchatPagesShowListRequest, useCaseIds);
    }
    if (network === 'tiktok') {
        yield call(tiktokPagesShowListRequest, useCaseIds);
    }

    if (network === 'threads') {
        yield call(threadsRedirectOauthRequest, useCaseIds);
    }
}

function* socialNetworkPagesShowListByStateRequest(action) {
    const { state } = action.payload;

    try {
        const serverRequest = createServerRequest({
            state
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-profiles-by-state-hash');
        if (response) {
            const { profiles } = response.jsonData;
            const parsedProfiles = parseSearchedProfiles(profiles);
            yield put(socialNetworkProfileShowListByStateSuccess(parsedProfiles));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListByStateError(applicationError));
    }
}

export default function* socialNetworkProfileSearchSagas() {
    yield takeEvery(PROFILE_SEARCH_BY_KEYWORD_REQUEST, profileSearchRequest);
    yield takeEvery(PROFILE_SEARCH_BY_URLS_REQUEST, profileBulkSearchRequest);
    yield takeEvery(PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST, profileSearchByAuthProfiles);
    yield takeEvery(SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST, socialNetworkPagesShowListRequest);
    yield takeEvery(SOCIAL_NETWORK_SEARCH_GRAPH_FORCE_CONNECT_REQUEST, socialNetworkSearchGraphForceConnectRequest);
    yield takeEvery(SOCIAL_NETWORK_SEARCH_LINKED_IN_FORCE_CONNECT_REQUEST, linkedInPagesShowListForceConnectRequest);
    yield takeEvery(SOCIAL_NETWORK_SEARCH_SNAPCHAT_FORCE_CONNECT_REQUEST, snapchatPagesShowListForceConnectRequest);
    yield takeEvery(SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST, socialNetworkPagesShowListByStateRequest);
}
